import { defineStore } from 'pinia'
import { useAccountStore } from './account'
import projectsApi from '@/api/modules/main-server/projects'
import importsApi from '@/api/modules/provider-server/imports'
import githubApi from '@/api/modules/main-server/github'
import { useUserStore } from './user'
import { useTeamStore } from './team'

const initialState = {
  loading: 0,

  // Autosave
  autosave: true,
  autosaveDelay: 30,

  // Projects paging
  projects: [],
  currentPage: 1,
  count: 0,
  range: [0, 0],
  pageSize: 20,
  pageLabel: 'Recent',
  filters: {
    name: '',
    shared: false,
    deleted: false,
    templates: false
  },
  sortBy: 'desc(last_activity_date)',

  history: [],

  project: null,
  readonly: false,
  savedProject: null,
  companies: [],
  selectedCompanies: [],

  importingProjectID: null,

  comparingProjectID: null
}

export const useProjectStore = defineStore('project', {
  state: () => initialState,

  getters: {
    isLoading: (state) => state.loading > 0,
    isImported: (state) => state.project?.imported !== null,
    projectID: (state) => state.project?.id
  },

  actions: {
    create (formData) {
      return projectsApi.create(formData)
    },
    edit (project) {
      return new Promise(
        (resolve, reject) => {
          projectsApi.put(project.id, project).then(
            ({ data }) => {
              this.setProject(data)
              this.setSavedProject(data)
              resolve()
            },
            (error) => reject(error)
          )
        }
      )
    },
    patch (project) {
      return new Promise(
        (resolve, reject) => {
          projectsApi.patch(project.id, project).then(
            ({ data }) => {
              this.setProject(data)
              this.setSavedProject(data)
              resolve()
            },
            (error) => reject(error)
          )
        }
      )
    },
    delete (projectID) {
      return new Promise(
        (resolve, reject) => {
          projectsApi.delete(projectID).then(
            () => {
              this.changePage(this.currentPage)
              resolve()
            },
            (error) => reject(error)
          )
        }
      )
    },
    deleteAll () {
      this.projects.forEach((project) => {
        this.delete(project.id).then(() => {
          this.setProjects(this.projects.filter((p) => p.id !== project.id))
        })
      })

      this.getPage().then(() => {
        // Iterate over all deleted projects because of the pagination, delete them page per page
        if (this.projects.length) {
          this.deleteAll()
        } else {
          // Deletion is over, fetch the quotas
          const accountStore = useAccountStore()
          accountStore.getQuotas()
        }
      })
    },
    async restore (projectID) {
      const projectChanges = {
        id: projectID,
        deleted: false
      }
      await this.patch(projectChanges)
      await this.changePage(this.currentPage)
    },
    // Reset filter and current page (new search or new category from left menu)
    getFilteredPage (data) {
      this.setCurrentPage(1)
      this.setFilters(data)
      this.getPage()
    },
    // Change page
    changePage (page) {
      this.setCurrentPage(page)
      this.getPage()
    },
    // Change page size
    changePageSize (pageSize) {
      this.setPageSize(pageSize)
      this.getPage()
    },
    getProjects (limit, filters = {}) {
      return new Promise((resolve) => {
        projectsApi.getPage(0, limit, filters).then(({ data }) => {
          this.setProjects(data.projects)
        })
      })
    },
    // Get new page
    getPage () {
      return new Promise((resolve) => {
        this.incrementIsLoading()
        const offset = (this.currentPage - 1) * this.pageSize
        if (this.filters.templates) {
          this.getTemplates().then((templates) => {
            const name = this.filters.name || ''
            const used = templates.filter((template) => (
              template.name.toLowerCase().includes(name.toLowerCase()) && this.selectedCompanies.includes(template.provider)
            ))
            this.setProjects(used)
            this.setCount(used.length)
            this.setRange([offset + 1, Math.min(used.length, offset + this.pageSize)])
            this.decrementIsLoading()
            resolve()
          })
        } else {
          projectsApi.getPage(offset, this.pageSize, {
            sort_by: this.sortBy,
            ...this.filters
          }).then(
            ({ headers, data }) => {
              // extract values
              const contentRangeHeader = headers['content-range'].split(' ', 2)[1].split('/')
              const count = +(contentRangeHeader[1])
              const range = contentRangeHeader[0].split('-').map((x) => parseInt(x, 10))
              // commit changes
              this.setCount(count)
              this.setRange(range)
              this.setProjects(data.projects)
              resolve()
            },
            (_error) => {
              this.setProjects([])
            }
          ).then(
            () => this.decrementIsLoading()
          )
        }
      })
    },
    refreshProject (id) {
      return new Promise((resolve, reject) => {
        projectsApi.get(id).then(
          ({ data }) => {
            const { deleted } = data

            if (!deleted) {
              this.projects = this.projects.map(p => p.id === id ? data : p)
              resolve(data)
            } else {
              this.projects = this.projects.filter(p => p.id !== id)
              reject(new Error('project was deleted'))
            }
          }
        )
      })
    },
    get (id) {
      // immutable approach to trigger vuejs change
      this.setProject(null)
      this.setReadonly(false)
      this.incrementIsLoading()
      return new Promise(
        (resolve, reject) => {
          projectsApi.get(id).then(
            (response) => {
              this.setProject(response.data)
              this.setSavedProject(response.data)

              const {
                user,
                shared_with: sharedWith,
                shared_with_team: sharedWithTeam
              } = response.data

              let readonly = false

              const userStore = useUserStore()
              const teamStore = useTeamStore()

              if ((!sharedWith.includes(userStore.getUserID) && user !== userStore.getUserID) || (sharedWithTeam && !teamStore.isOwnerOfTeam(sharedWithTeam))) {
                readonly = true
              }

              this.setReadonly(readonly)

              resolve(response)
            },
            (error) => {
              this.setProject(null)
              reject(error)
            }
          ).then(
            () => this.decrementIsLoading()
          )
        }
      )
    },
    getProjectThumbnail (projectID) {
      return projectsApi.getThumbnail(projectID).catch(() => {})
    },
    getFromProvider (projectID, providerName, installationID) {
      let promise = null

      switch (providerName) {
        case 'github':
          promise = githubApi.getProjectByID(installationID, projectID)
          break
      }

      if (promise) {
        this.setProject(null)
        this.incrementIsLoading()

        return new Promise((resolve, reject) => {
          promise.then(
            ({ data }) => {
              this.setProject(data)
              this.setReadonly(true)
              this.setSavedProject(data)
              resolve(data)
            },
            (error) => {
              this.setProject(null)
              reject(error)
            }).then(() => {
            this.decrementIsLoading()
          })
        })
      }
    },
    getHistory (id) {
      this.setHistory([])
      return new Promise(
        (resolve, reject) => {
          projectsApi.getHistory(id).then(
            ({ data }) => {
              this.setHistory(data.history)
              resolve()
            },
            (error) => {
              this.setHistory([])
              reject(error)
            }
          )
        }
      )
    },
    async favorite (id) {
      return await projectsApi.favorite(id).catch(
        () => {}
      )
    },
    async unfavorite (id) {
      return await projectsApi.unfavorite(id).catch(
        () => {}
      )
    },
    unyokeUsers ({ id, unyokeUsers }) {
      return new Promise(
        (resolve, reject) => {
          projectsApi.unyoke_users(id, { users: unyokeUsers }).then(
            ({ data }) => {
              this.setProject(data)
              this.setSavedProject(data)
              resolve()
            },
            (error) => reject(error)
          )
        }
      )
    },
    acceptInvitation (projectID) {
      return new Promise(
        (resolve, reject) => {
          projectsApi.accept_invitation(projectID).then(
            ({ data }) => {
              this.setProject(data)
              this.setSavedProject(data)
              resolve()
            },
            (error) => reject(error)
          )
        }
      )
    },
    declineInvitation ({ commit }, projectID) {
      return new Promise(
        (resolve, reject) => {
          projectsApi.decline_invitation(projectID).then(
            ({ data }) => {
              this.setProject(data)
              this.setSavedProject(data)
              resolve()
            },
            (error) => reject(error)
          )
        }
      )
    },
    async setCompanies () {
      const companies = []
      this.getTemplates().then((templates) => {
        for (const template of templates) {
          const company = companies.find(c => c.name === template.provider)
          if (company) {
            company.count += 1
          } else {
            companies.push({ name: template.provider, count: 1 })
          }
        }
        this.companies = companies
      })
    },
    import ({ providerAccountID, projectID, regions, serviceOptions }) {
      return importsApi.import(
        providerAccountID,
        projectID,
        regions,
        serviceOptions
      )
    },
    setProject (project) {
      this.project = project
    },
    setSavedProject (project) {
      this.savedProject = project
    },
    setProjects (projects) {
      this.projects = projects
    },
    setHistory (history) {
      this.history = history
    },
    toggleAutosave (state) {
      this.autosave = !this.autosave
    },
    setAutosave (autosave) {
      this.autosave = autosave
    },
    setAutosaveDelay (autosaveDelay) {
      this.autosaveDelay = autosaveDelay
    },
    // paging
    setPageSize (pageSize) {
      this.pageSize = pageSize
    },
    setCurrentPage (currentPage) {
      this.currentPage = currentPage
    },
    setPageLabel (pageLabel) {
      this.pageLabel = pageLabel
    },
    setRange (range) {
      this.range = range
    },
    setCount (count) {
      this.count = count
    },
    setSelectedCompanies (value) {
      this.selectedCompanies = value
    },
    // loading
    incrementIsLoading (state) {
      this.loading++
    },
    decrementIsLoading (state) {
      this.loading--
    },
    setFilters (item) {
      this.filters = {
        ...item
      }
    },
    setImportingProjectID (importingProjectID) {
      this.importingProjectID = importingProjectID
    },
    getTemplates () {
      return new Promise((resolve, reject) => {
        projectsApi.getTemplates().then(
          ({ data }) => {
            const keys = Object.keys(data)
            const templates = keys.map((id) => ({
              id,
              ...data[id],
              thumbnail: `/templates/${id}/thumbnail.png`
            }))
            resolve(templates)
          },
          (error) => reject(error)
        )
      })
    },
    getTemplate (template) {
      return new Promise((resolve, reject) => {
        projectsApi.getTemplate(template).then(
          ({ data }) => {
            projectsApi.getTemplateThumbnail(template).then((res) => {
              const reader = new FileReader()
              reader.onloadend = function () {
                resolve({
                  ...data,
                  thumbnail: reader.result
                })
              }
              reader.readAsDataURL(res.data)
            })
          },
          (error) => reject(error)
        )
      })
    },
    uploadTerraformFile (projectID, file) {
      return importsApi.uploadTerraformFile(projectID, file)
    },
    setSortBy (sortBy) {
      this.sortBy = sortBy
    },
    setComparingProjectID (comparingProjectID) {
      this.comparingProjectID = comparingProjectID
    },
    setReadonly (readonly) {
      this.readonly = readonly
    }
  }
})
