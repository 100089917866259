import { makeTitle } from '@/common/meta'
import EventBus from '@/common/eventBus'
import PasswordFormat from '@/components/PasswordFormat.vue'

const toastIcons = {
  warning: 'alert',
  info: 'information',
  success: 'check-circle',
  danger: 'close-circle'
}

const toastOptions = {
  hasIcon: true,
  iconSize: 'medium',
  rootClass: 'toast-notification',
  position: 'bottom',
  closable: true,
  duration: 5000,
  queue: true
}

const mixins = {
  methods: {
    setPageTitle (title) {
      EventBus.dispatch('setPageTitle', title)
      this.$nextTick(() => {
        document.title = makeTitle(title)
      })
    },

    closeAllToasts () {
      this.$oruga.notification.closeAll({ action: 'closeAll' })
    },

    toast (options) {
      this.$oruga.notification.open({
        ...toastOptions,
        icon: toastIcons[options.variant || 'info'],
        ...options
      })
    },

    navigate (name, more = {}) {
      this.$router.push({ name, ...more }).catch(() => {})
    },

    backToProjects () {
      this.$router.replace({ name: 'Projects' })
    }
  }
}

const checkPassword = (password) => {
  if (!password) {
    return 'No password'
  }

  if (!password.length >= 8) {
    return 'Minimum 8 characters required.'
  }

  if (!/\d+/.test(password)) {
    return 'At least 1 digit required.'
  }

  if (!/[A-Z]+/.test(password)) {
    return 'At least 1 uppercase letter required.'
  }

  if (!/[a-z]+/.test(password)) {
    return 'At least 1 lowercase letter required.'
  }

  return ''
}

const passwordMixin = {
  components: {
    PasswordFormat
  },

  computed: {
    passwordPattern () {
      return '(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,}'
    },

    passwordMessage () {
      return checkPassword(this.password)
    },
    newPasswordMessage () {
      return checkPassword(this.newPassword)
    },
    passwordConfirmationMessage () {
      return checkPassword(this.newPasswordConfirmation)
    }
  }
}

export {
  mixins,
  passwordMixin
}
