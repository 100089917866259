<template>
  <o-button
    variant="primary"
    class="is-block has-text-weight-bold mx-auto mt-5"
    icon-left="arrow-up-bold-hexagon-outline"
    native-tag="a"
    @click="click"
  >
    Upgrade your account
  </o-button>
</template>

<script>
import EventBus from '@/common/eventBus'

export default {
  props: {
    redirect: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  methods: {
    click () {
      this.$emit('click')

      if (this.redirect) {
        EventBus.dispatch('upgradePricingPlan')
      }
    }
  }
}
</script>
