import axios from 'axios'
import { instance as mainServerInstance } from '@/api'
import mainServerUrl from '@/api/modules/main-server'
import providerServerUrl from '@/api/modules/provider-server'

export const baseURL = 'https://api.github.com/'

export const instance = axios.create({
  baseURL,
  timeout: 60000,
  headers: {
    Accept: 'application/vnd.github+json',
    'X-GitHub-Api-Version': '2022-11-28'
  }
})

const setToken = (token) => {
  instance.defaults.headers.Authorization = `Bearer ${token}`
}

const getLogin = (organization) => organization.startsWith('@') ? organization.substr(1) : organization

export default {
  /**
   * Gets the github access token for the user, stores in in the Authorization header and resolves.
   * @returns {Promise}
   */
  getToken () {
    return new Promise((resolve) => {
      if (instance.defaults.headers.Authorization) {
        resolve()
        return
      }

      mainServerInstance.get(mainServerUrl + 'github/token').then(({ data }) => {
        const { access_token: token } = data
        setToken(token)
        resolve()
      })
    })
  },

  /**
   * Retrieve the list of organizations on which the Holori application is installed for the current user.
   * @returns {Promise<Array<Object>>}
   */
  getInstalledOrganizations (accountID) {
    return new Promise((resolve, reject) => {
      mainServerInstance.get(mainServerUrl + 'github/installations').then(
        ({ data: { apps } }) => {
          // In `apps`, we have the list of installations.
          // `apps` is an array of objets with `organization_id` in it.
          /** @type {Array<Promise>} */
          const promises = apps.map(({ organization_id: organizationID }) => {
            return this.getOrganization(organizationID, accountID).catch((err) => reject(err))
          })
          Promise.all(promises).then(
            (responses) => {
              const validResponses = responses.filter((response) => response && response.request !== undefined)
              const mappedResponses = validResponses.map(
                (response) => {
                  const { id, login: dataLogin, type } = response.data
                  const login = type === 'User' ? `@${dataLogin}` : dataLogin

                  const app = apps.find((app) => app.organization_id === '' + id)
                  const { installation_id: installationID } = app

                  return {
                    id,
                    installationID,
                    login
                  }
                })
              resolve(mappedResponses)
            },
            (err) => reject(err)
          )
        },
        (err) => reject(err)
      )
    })
  },

  /**
   * Retrieve the user account.
   * @returns {Promise<AxiosResponse>}
   */
  async getUserAccount () {
    await this.getToken()
    return instance.get('user')
  },

  /**
   * Retrieve the list of the organizations to which the logged user has access.
   * @returns {Promise<AxiosResponse>}
   */
  async getOrganizations () {
    await this.getToken()
    return instance.get('user/orgs')
  },

  /**
   * Retrieve information about an organization.
   * @param {String} organization The name of the organization to retrieve.
   * @param {Number|String} accountID The ID of the account of the user. Used to trigger the correct call.
   * @returns {Promise<AxiosResponse>}
   */
  async getOrganization (organization, accountID = undefined) {
    await this.getToken()
    const url = (organization.startsWith('@') || organization === ('' + accountID)) ? 'user' : `orgs/${organization}`
    return instance.get(url)
  },

  /**
   * Retrieve the list of repositories linked to an organization.
   * @param {String} organization The name of the organization (not case sensitive).
   * @returns {Promise<AxiosResponse>}
   */
  async getRepositoriesForOrganization (organization, signal) {
    await this.getToken()
    const url = organization.startsWith('@') ? 'user/repos' : `orgs/${organization}/repos`

    return instance.get(url, { signal })
  },

  /**
   * Retrieve the list of projects attached to a repository.
   * @param {String} installationID The ID of the installation, coming from database.
   * @param {String} name The name of the project to filter with (`${organization}/${repository} - ${commit}`)
   * @returns {Promise<AxiosResponse>}
   */
  getProjectsForName (installationID, name, signal, limit = undefined) {
    return mainServerInstance.get(mainServerUrl + 'github/installations/' + installationID + '/projects', {
      signal,
      params: {
        name,
        limit
      }
    })
  },

  /**
   * Retrieve the list of pull requests linked to a repository.
   * @param {String} organization The name of the organization (not case sensitive).
   * @param {String} repository The name of the repository (not case sensitive).
   * @returns {Promise<AxiosResponse>}
   */
  async getPullRequestsForRepository (organization, repository, signal, page, limit = 10) {
    await this.getToken()
    return instance.get(`repos/${getLogin(organization)}/${repository}/pulls?page=${page}&per_page=${limit}`, { signal })
  },

  /**
   * Retrieve the information of a pull request.
   * @param {String} organization The name of the organization (not case sensitive).
   * @param {String} repository The name of the repository (not case sensitive).
   * @param {String} number The number of the pull request.
   * @returns {Promise<AxiosResponse>}
   */
  async getPullRequest (organization, repository, number) {
    await this.getToken()
    return instance.get(`repos/${getLogin(organization)}/${repository}/pulls/${number}`)
  },

  /**
   * Retrieve the information of a commit.
   * @param {String} organization The name of the organization (not case sensitive).
   * @param {String} repository The name of the repository (not case sensitive).
   * @param {String} commitSha The sha ref of the commit.
   * @returns {Promise<AxiosResponse>}
   */
  async getCommit (organization, repository, commitSha) {
    await this.getToken()
    return instance.get(`repos/${getLogin(organization)}/${repository}/commits/${commitSha}`)
  },

  /**
   * Retrieve the list of branches for a repository.
   * @param {String} organization The name of the organization (not case sensitive).
   * @param {String} repository The name of the repository (not case sensitive).
   * @returns {Promise<AxiosResponse>}
   */
  async getBranchesForRepository (organization, repository, signal) {
    await this.getToken()
    const owner = organization.startsWith('@') ? organization.substring(1) : organization
    const url = `repos/${owner}/${repository}/branches`

    return instance.get(url, { signal })
  },

  importFromZIP (projectID, organization, repository, branch) {
    const url = `${baseURL}repos/${organization}/${repository}/tarball/${branch}`
    const headers = instance.defaults.headers

    return mainServerInstance.post(providerServerUrl + 'github/import', {
      project_id: projectID,
      tarball_url: url,
      headers: {
        Accept: headers.Accept,
        'X-GitHub-Api-Version': headers['X-GitHub-Api-Version'],
        Authorization: headers.Authorization
      }
    })
  }
}
