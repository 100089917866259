export default {
  inserted (el, binding, vnode) {
    const shortcut = binding.value.toLowerCase()
    const split = shortcut.split('+')

    const requiresCtrl = split.includes('ctrl')
    const requiresShift = split.includes('shift')

    const actionKey = split.pop()

    el._handleKeyboardShortcut = (e) => {
      const { ctrlKey, shiftKey, key, target: { tagName } } = e

      if (['INPUT', 'TEXTAREA'].includes(tagName)) {
        return
      }

      // Stop if modifiers requirement are not met.
      if ((requiresCtrl && !ctrlKey) || (requiresShift && !shiftKey)) {
        return
      }

      if (key.toLowerCase() === actionKey) {
        e.preventDefault()

        const { click } = (vnode.data && vnode.data.on) || (vnode.componentOptions && vnode.componentOptions.listeners)
        const disabled = el.getAttribute('disabled') === 'disabled'

        if (click && !disabled) {
          click(e)
        }
      }
    }

    document.addEventListener('keydown', el._handleKeyboardShortcut)
  },

  unbind (el) {
    document.removeEventListener('keydown', el._handleKeyboardShortcut)
  }
}
