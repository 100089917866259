<template>
  <div class="modal-card mx-auto">
    <header class="modal-card-head">
      <p class="modal-card-title">
        Install GitHub app
      </p>
    </header>
    <section class="modal-card-body">
      <p class="has-text-centered">
        Redirecting you to GitHub App installation&hellip;
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ImportingProjectModal',

  mounted () {
    setTimeout(() => {
      document.getElementById('github-app-install-link').click()
      window.localStorage.removeItem('installGithubApp')
    }, 1000)
  }
}
</script>
