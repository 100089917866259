import filtersApi from '@/api/modules/price-server/filters'
import offersApi from '@/api/modules/price-server/offers'
import axios from 'axios'
import { defineStore } from 'pinia'
import { useCompareStore } from './compare'

const initialState = {
  offerPage: [],
  offerCount: 0,
  offerRange: [0, 0],
  offerPageSize: 50,
  offerCurrentPage: 1,

  abortControllers: {
    offer_page: null,
    filters: null
  },

  filtersRange: {
    currency: [],
    payment_period: ['hour'],
    price: [0, 0],
    cpu: [0, 0],
    ram: [0, 0],
    disk: [0, 0],
    gpu: [0, 0],
    internal_network_speed: [0, 0],
    external_network_speed: [0, 0],
    location: []
  },

  filters: {
    name: null,
    currency: null,
    payment_period: 'hour',
    dedicated: null,
    baremetal: null,
    min_price: 0,
    max_price: 0,
    min_cpu: 0,
    max_cpu: 0,
    min_ram: 0,
    max_ram: 0,
    min_disk: 0,
    max_disk: 0,
    min_gpu: 0,
    max_gpu: 0,
    min_internal_network_speed: 0,
    max_internal_network_speed: 0,
    min_external_network_speed: 0,
    max_external_network_speed: 0,
    location: [],
    company: [],
    tags: []
  },

  sorting: {
    sortBy: 'price',
    sortDir: 'asc'
  }
}

export const useComputeStore = defineStore('compute', {
  state: () => initialState,

  actions: {
    initFilters (queryParams) {
      const initValues = {
        min_price: Math.floor(this.filtersRange.price[0]),
        max_price: this.filtersRange.price[1],
        min_cpu: this.filtersRange.cpu[0],
        max_cpu: this.filtersRange.cpu[1],
        min_ram: this.filtersRange.ram[0],
        max_ram: this.filtersRange.ram[1],
        min_disk: this.filtersRange.disk[0],
        max_disk: this.filtersRange.disk[1],
        min_gpu: this.filtersRange.gpu[0],
        max_gpu: this.filtersRange.gpu[1],
        min_internal_network_speed: this.filtersRange.internal_network_speed[0],
        max_internal_network_speed: this.filtersRange.internal_network_speed[1],
        min_external_network_speed: this.filtersRange.external_network_speed[0],
        max_external_network_speed: this.filtersRange.external_network_speed[1],
        name: null,
        location: [],
        company: [],
        tags: []
      }

      this.filters = {
        ...initValues,
        ...queryParams
      }
    },
    getFilters (paymentPeriod = initialState.filters.payment_period) {
      const compare = useCompareStore()
      compare.incrementLoading('filters')

      this.abortControllers.filters?.abort()
      this.updateAbortControllers({ filters: new AbortController() })

      return new Promise(
        (resolve, reject) => {
          filtersApi.get(
            '/compute',
            {
              payment_period: paymentPeriod,
              currency: this.filters.currency
            },
            this.abortControllers.filters.signal
          ).then(
            (response) => {
              this.setFiltersRange(response.data)
              resolve()
            },
            (error) => {
              if (!axios.isCancel(error)) {
                console.error(error)
                reject(error)
              }
            }
          ).then(
            () => compare.decrementLoading('filters')
          )
        }
      )
    },
    async updateFilters (filters) {
      this.filters = {
        ...this.filters,
        ...filters
      }

      await this.getFilters()

      if (filters.payment_period) {
        await this.resetPriceRange()
      }
    },
    getOfferPage () {
      const compare = useCompareStore()
      compare.incrementLoading('offers')

      return new Promise(
        (resolve, reject) => {
          this.abortControllers.offer_page?.abort()
          this.updateAbortControllers({ offer_page: new AbortController() })

          offersApi.getPage(
            '/compute',
            (this.offerCurrentPage - 1) * this.offerPageSize,
            this.offerPageSize,
            this.filters,
            this.sorting,
            this.abortControllers.offer_page.signal
          ).then(
            (response) => {
              // extract values
              const contentRangeHeader = response.headers['content-range'].split(' ', 2)[1].split('/')
              const offerCount = +(contentRangeHeader[1])
              const offerRange = contentRangeHeader[0].split('-').map((x) => parseInt(x, 10))

              // commit changes
              this.setOfferCount(offerCount)
              this.setOfferRange(offerRange)
              this.setOfferPage(response.data.offers)
              resolve()
            },
            error => {
              if (!axios.isCancel(error)) {
                console.error(error)
                reject(error)
              }
            }
          ).then(
            () => compare.decrementLoading('offers')
          )
        }
      )
    },
    updateOfferPageSize (size) {
      this.setOfferPageSize(size)
      this.getOfferPage()
    },
    updateOfferCurrentPage (page) {
      this.setOfferCurrentPage(page)
      this.getOfferPage()
    },
    resetPriceRange () {
      this.updateFilters({
        min_price: Math.floor(this.filtersRange.price[0]),
        max_price: Math.ceil(this.filtersRange.price[1])
      })
    },
    updateSort (sort) {
      this.sorting = sort
    },
    setFiltersRange (filtersRange) {
      this.filtersRange = filtersRange
    },
    // offers
    setOfferCount (offerCount) {
      this.offerCount = offerCount
    },
    setOfferRange (offerRange) {
      this.offerRange = offerRange
    },
    setOfferPage (offerList) {
      this.offerPage = offerList
    },
    setOfferPageSize (offerPageSize) {
      this.offerPageSize = offerPageSize
    },
    setOfferCurrentPage (offerCurrentPage) {
      this.offerCurrentPage = offerCurrentPage
    },
    // Abort Controllers
    updateAbortControllers (controllers) {
      this.abortControllers = {
        ...this.abortControllers,
        ...controllers
      }
    }
  }
})
