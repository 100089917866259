import migration0 from './migrations/migration-0-1'

export const MIGRATION_VERSION = 1

export const needsMigration = (version) => version < MIGRATION_VERSION

export const migrate = (data, progress) => {
  const playMigration = (migration, targetVersion) => {
    const updateProgress = (max, counter) => {
      progress.max = max
      progress.value = counter

      if (counter >= max) {
        data.version = targetVersion
      }
    }

    migration0(data, updateProgress)
  }

  switch (data.version) {
    case 0:
      playMigration(migration0, 1)
  }
}
