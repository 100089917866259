import instance from '@/api'
import mainServerUrl from '@/api/modules/main-server'

export const teamsApiUrl = mainServerUrl + 'teams'

export default {
  // eslint-disable-next-line camelcase
  getPage (offset, limit, filters) {
    return instance.get(
      teamsApiUrl,
      {
        params: {
          ...filters,
          offset,
          limit
        }
      }
    )
  },
  create (formData) {
    return instance.post(
      teamsApiUrl,
      formData
    )
  },
  get (id) {
    return instance.get(
      `${teamsApiUrl}/${id}`
    )
  },
  patch (id, fields) {
    return instance.patch(
      `${teamsApiUrl}/${id}`,
      fields
    )
  },
  put (id, team) {
    return instance.put(
      `${teamsApiUrl}/${id}`,
      team
    )
  },
  delete (id) {
    return instance.delete(
      `${teamsApiUrl}/${id}`
    )
  },
  unyoke_users (id, unyokeUsers) {
    return instance.post(
      `${teamsApiUrl}/${id}/unyoke`,
      unyokeUsers
    )
  },
  accept_invitation (id) {
    return instance.post(
      `${teamsApiUrl}/invitations/${id}`
    )
  },
  decline_invitation (id) {
    return instance.delete(
      `${teamsApiUrl}/invitations/${id}`
    )
  }
}
