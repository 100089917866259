<template>
  <div
    class="modal-card"
    style="width: auto"
  >
    <header class="modal-card-head">
      <p class="modal-card-title">
        Project history log
      </p>
      <o-button
        icon-left="close"
        class="ml-auto pl-4"
        @click="closePopup"
      />
    </header>
    <section class="modal-card-body">
      <o-field>
        <o-input
          ref="input"
          type="search"
          rounded
          clearable
          placeholder="Search by date or user"
        />
      </o-field>
      <div class="history-list overflow">
        <o-table
          v-show="data.length"
          detailed
          striped
          :selected="selectedRow"
          :data="data"
          :columns="columns"
          :sticky-header="true"
          :is-row-selectable="canSelectRow"
          :opened-detailed="openedDetails"
          detail-key="index"
          @details-open="selectRow"
        >
          <template #detail="props">
            <div class="is-flex is-justify-content-center">
              <img
                :src="props.row.thumbnail"
                alt="No humbnail available"
                style="max-height: 200px;"
              >
            </div>
            <o-button
              v-if="!!history[props.row.index].changes.diagram"
              @click="compare(props.row)"
            >
              Compare
            </o-button>
          </template>
        </o-table>
        <o-loading
          :active="isLoading"
          :full-page="false"
        />
      </div>
    </section>
    <footer class="modal-card-foot is-justify-content-center">
      <template v-if="canAccessHistory">
        <o-button
          v-if="selectedRow"
          variant="primary"
          icon-left="file-restore-outline"
          @click="restore"
        >
          Restore selected version
        </o-button>
        <p v-else>
          You can select an history entry to restore it.
        </p>
      </template>
      <template v-else>
        <p>
          You cannot restore history because you are using a Free plan.
        </p>
        <p>
          Please click the button below to upgrade to a superior plan. This will allow you to restore a history entry.
        </p>
        <QuotaUpgradeButton
          redirect
          @click="closePopup"
        />
      </template>
    </footer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import cloneDeep from 'clone-deep'
import EventBus from '@/common/eventBus'
import { useUserStore } from '@/store/user'
import { useProjectStore } from '@/store/project'
import { useAccountStore } from '@/store/account'
import { useDiagramStore } from '@/store/diagram'
import QuotaUpgradeButton from '@/components/QuotaUpgradeButton.vue'
import { compareTwoDiagrams } from '@/common/diagram-options/compare'

export default {
  name: 'ProjectHistoryPopup',

  components: {
    QuotaUpgradeButton
  },

  data () {
    return {
      isLoading: true,
      data: [],
      users: {},
      selectedRow: null,
      columns: [
        {
          field: 'date',
          label: 'Date',
          width: '225'
        },
        {
          field: 'user',
          label: 'User',
          width: '100'
        },
        {
          field: 'changes',
          label: 'Changes'
        }
      ],
      changes: '',
      openedDetails: []
    }
  },
  computed: {
    ...mapState(useProjectStore, ['history', 'project']),
    ...mapState(useAccountStore, ['userID']),
    ...mapState(useDiagramStore, ['comparingRow', 'settings']),
    ...mapState(useUserStore, ['getQuota', 'getPricingPlan']),

    diagram () {
      return JSON.parse(this.project.diagram)
    },

    projectID () {
      return this.project.id
    },
    projectName () {
      return this.project.name
    },

    canAccessHistory () {
      return this.getPricingPlan !== 'free'
    }
  },
  async mounted () {
    await this.getHistory(this.projectID)
    // Copy history to data
    this.data = []
    for (let index = this.history?.length - 1; index >= 0; index--) {
      const item = this.history[index]
      const entry = {}
      entry.date = new Date(item.date).toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'medium' })
      entry.thumbnail = item.changes.thumbnail
      entry.index = index

      if (!index) {
        entry.changes = 'Initial diagram'
      } else {
        // changes between 2 saved item
        this.changes = ''
        for (const k of Object.keys(item.changes)) {
          const change = item.changes[k]
          // If the items to be compared are lists we have to compare by length (for now)
          if (k === 'deleted') {
            this.changes += this.changes ? ', ' : ''
            this.changes += change ? 'project deleted' : 'project restored'
          } else if (k === 'shared_with') {
            this.changes += this.changes ? ', ' : ''
            this.changes += 'shared list updated'
          } else if (k === 'favorite_of') {
            this.changes += ''
          } else {
            this.changes += this.changes ? ', ' : ''
            this.changes += k + ' updated'
          }
        }
        entry.changes = this.changes
      }

      // If someone else did the change, fetch their name.
      if (item.user !== this.userID) {
        const res = await this.get(item.user)
        if (res.data?.name) {
          entry.user = res.data.name
        } else {
          entry.user = item.user
        }
      } else {
        entry.user = 'You'
      }

      this.data.push(entry)
    }

    if (this.comparingRow !== null) {
      // this.selectedRow = this.comparingRow
      this.openedDetails = [this.comparingRow]
      this.selectedRow = this.data.find((row) => row.index === this.comparingRow)
      this.setComparingRow(null)
    }

    this.isLoading = false
  },
  methods: {
    ...mapActions(useUserStore, ['get']),
    ...mapActions(useProjectStore, ['patch', 'getHistory', 'setAutosave']),
    ...mapActions(useDiagramStore, ['saveDiagram', 'setSaved', 'setComparing', 'setComparingRow', 'addUpdatedTerraformKey']),

    closePopup () {
      this.$emit('close')
    },
    restore () {
      const { diagram, thumbnail } = this.history[this.selectedRow.index].changes
      EventBus.dispatch('reloadDiagram', diagram)
      this.patch({
        id: this.projectID,
        diagram,
        thumbnail
      }).then(() => {
        this.closePopup()
        this.toast({
          message: `Project "${this.projectName}" has been restored to previous version`,
          variant: 'success'
        })
      })
    },
    canSelectRow (row) {
      const { index } = row
      return index < this.history.length - 1
    },
    selectRow (row) {
      if (this.canSelectRow(row)) {
        this.selectedRow = row
      }
    },

    compareTwoDiagrams,

    compare (row) {
      const { graphInstance } = window

      // Remove any selection
      graphInstance.emit('canvas:click')

      const compareTo = JSON.parse(this.history[row.index].changes.diagram)
      const diagram = cloneDeep(this.diagram)

      this.compareTwoDiagrams(diagram, compareTo, graphInstance, this.addUpdatedTerraformKey)

      const items = [
        ...graphInstance.getNodes(), ...graphInstance.getCombos(), ...graphInstance.getEdges()
      ]

      for (const item of items) {
        item.enableCapture(false)
      }

      this.setComparing(true)
      this.setComparingRow(row.index)
      this.setAutosave(false)
      this.closePopup()
    }
  }
}
</script>

<style scoped>
  .history-list {
    min-height: 25vh;
    min-width: 30vw;
  }
  :deep() .b-table .table tr.is-selected .chevron-cell > .icon {
    color: #fff !important;
  }
</style>
