<template>
  <div>
    <a
      class="dropdown-item is-flex is-align-items-center is-whitespace-normal"
      href="https://doc.holori.com"
      aria-role="listitem"
    >
      <o-icon
        icon="book-open-variant"
        class="mr-2"
      />
      Documentation
    </a>
    <a
      v-if="canHaveKeyboardShortcuts"
      v-keyboard-shortcut="'Ctrl+/'"
      class="dropdown-item is-flex is-align-items-center is-whitespace-normal"
      aria-role="listitem"
      @click="openKeyboardShortcuts"
    >
      <o-icon
        icon="keyboard"
        class="mr-2"
      />
      Keyboard Shortcuts
    </a>
    <a
      class="dropdown-item is-flex is-align-items-center is-whitespace-normal"
      href="https://www.youtube.com/@holori_cloud"
      target="_blank"
      aria-role="listitem"
    >
      <o-icon
        icon="youtube"
        class="mr-2"
      />
      YouTube tutorials
    </a>
    <a
      class="dropdown-item is-flex is-align-items-center is-whitespace-normal"
      aria-role="listitem"
      @click="openCalendly"
    >
      <o-icon
        icon="bulletin-board"
        class="mr-2"
      />
      Dedicated onboarding
    </a>
    <a
      class="dropdown-item is-flex is-align-items-center is-whitespace-normal"
      href="https://holori.com/contact"
      target="_blank"
      aria-role="listitem"
    >
      <o-icon
        icon="chat-question"
        class="mr-2"
      />
      Contact Us
    </a>
  </div>
</template>

<script>
import eventBus from '../common/eventBus'

export default {
  props: {
    canHaveKeyboardShortcuts: {
      type: Boolean,
      required: true
    }
  },

  methods: {
    openCalendly () {
      eventBus.dispatch('openCalendly')
    },
    openKeyboardShortcuts () {
      eventBus.dispatch('openKeyboardShortcuts')
    }
  }
}
</script>
