<template>
  <div
    id="dropdown-menu"
    class="dropdown-menu is-fixed"
    role="menu"
  >
    <div class="dropdown-content">
      <template v-if="displayNavigation">
        <a
          v-for="view in Object.keys(navigationViews)"
          :key="view"
          class="dropdown-item is-flex is-align-items-center is-whitespace-normal"
          :class="{ 'is-active': view.toLowerCase() === navigationSelectedView }"
          @click="$emit('navigate', $event)"
        >
          <o-icon
            :icon="navigationViews[view]"
            class="ml-0 mr-2"
          />
          {{ view }}
        </a>

        <hr class="dropdown-divider">
      </template>

      <slot />

      <hr class="dropdown-divider">

      <HelpMenu :can-have-keyboard-shortcuts="canHaveKeyboardShortcuts" />

      <hr class="dropdown-divider">

      <a
        class="dropdown-item is-flex is-align-items-center is-whitespace-normal"
        @click="gotoPage('Account')"
      >
        <o-icon
          icon="account"
          class="ml-0 mr-2"
        />
        {{ getUsername }}
      </a>

      <a
        class="dropdown-item is-flex is-align-items-center is-whitespace-normal"
        @click="upgradePlan"
      >
        <o-icon
          icon="apps-box"
          class="ml-0 mr-2"
        />
        Current plan:&nbsp;
        <strong class="is-capitalized">{{ getPricingPlan }}</strong>
      </a>

      <a
        class="dropdown-item is-flex is-align-items-center is-whitespace-normal"
        @click="upgradePlan"
      >
        <o-icon
          icon="arrow-up-bold-hexagon-outline"
          class="ml-0 mr-2"
        />
        Upgrade
      </a>

      <a
        class="dropdown-item is-flex is-align-items-center is-whitespace-normal"
        @click="logout"
      >
        <o-icon
          icon="logout"
          class="ml-0 mr-2"
        />
        Logout
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useUserStore } from '@/store/user'
import EventBus from '@/common/eventBus'
import HelpMenu from '@/components/HelpMenu.vue'

export default {
  components: {
    HelpMenu
  },

  props: {
    displayNavigation: {
      type: Boolean,
      required: true
    },
    navigationViews: {
      type: Object,
      required: true
    },
    navigationSelectedView: {
      type: String,
      required: true
    },
    canHaveKeyboardShortcuts: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    ...mapState(useUserStore, ['getUsername', 'getPricingPlan'])
  },

  methods: {
    gotoPage (name) {
      this.$router.push({ name })
    },

    upgradePlan () {
      EventBus.dispatch('upgradePricingPlan')
    },

    logout () {
      EventBus.dispatch('logout')
    }
  }
}
</script>

<style scoped>
/* Hide the divider if there is nothing before it. */
.dropdown-divider:first-child {
  display: none;
}
</style>
