export const compareTwoDiagrams = (diagram, compareTo, graph, addUpdatedTerraformKey) => {
  const hasItem = (id, type, from) => from[type].findIndex((item) => item.id === id) !== -1
  const findById = (id, where) => where.nodes.find((node) => node.id === id)

  const added = {
    nodes: [],
    edges: [],
    combos: []
  }
  const removed = {
    nodes: [],
    edges: [],
    combos: []
  }
  const unchanged = {
    nodes: [],
    edges: [],
    combos: []
  }
  const updated = {
    nodes: [],
    combos: []
  }

  const compareObj = (valueBefore, valueAfter, item, type, key) => {
    Object.keys(valueBefore).forEach((subkey) => {
      let valBefore = valueBefore[subkey]
      let valAfter = valueAfter[subkey]

      // Compare two strings and not two objects
      if (typeof valueBefore[subkey] === 'object') {
        valBefore = JSON.stringify(valBefore)
        valAfter = JSON.stringify(valAfter)
      }

      if (valBefore !== valAfter) {
        updated[type].push({
          ...item,
          updated: true
        })
        if (typeof addUpdatedTerraformKey === 'function') {
          addUpdatedTerraformKey({
            id: item.id,
            key: `${key}/${subkey}`,
            value: valueBefore[subkey]
          })
        }
      } else {
        unchanged[type].push(item)
      }
    })
  }

  const types = ['combos', 'nodes', 'edges']

  types.forEach((type) => {
    // Check for added
    const fromDiagram = diagram[type] || []
    fromDiagram.forEach((item) => {
      // Skip placeholder
      if (item.type === 'text-box' && item.notes === 'empty') {
        return
      }

      const unchange = () => unchanged[type].push(item)

      if (!hasItem(item.id, type, compareTo)) {
        added[type].push({
          ...item,
          added: true
        })
      } else {
        if (type === 'nodes' || type === 'combos') {
          const before = findById(item.id, compareTo)
          const after = findById(item.id, diagram)

          if (before !== undefined && after !== undefined) {
            const beforeTerraform = before.terraform
            const afterTerraform = after.terraform

            if (beforeTerraform && afterTerraform) {
              const keys = Object.keys(afterTerraform)
              if (keys.length) {
                keys.forEach((key) => {
                  const valueBefore = beforeTerraform[key]
                  const valueAfter = afterTerraform[key]

                  if (typeof valueBefore === 'object') {
                    compareObj(valueBefore, valueAfter, item, type, key)
                  } else {
                    if (valueBefore !== valueAfter) {
                      updated[type].push({
                        ...item,
                        updated: true
                      })
                      if (typeof addUpdatedTerraformKey === 'function') {
                        addUpdatedTerraformKey({
                          id: item.id,
                          key,
                          value: valueBefore
                        })
                      }
                    } else {
                      unchange()
                    }
                  }
                })
              } else {
                unchange()
              }
            } else {
              unchange()
            }
          } else {
            unchange()
          }
        } else {
          unchange()
        }
      }
    })

    const fromCompare = compareTo[type] || []

    fromCompare.forEach((item) => {
      // Skip placeholder
      if (item.type === 'text-box' && item.notes === 'empty') {
        return
      }

      if (!hasItem(item.id, type, diagram)) {
        removed[type].push({
          ...item,
          removed: true
        })
      }
    })
  })

  const newEdges = Array.prototype.concat([], added.edges, removed.edges, unchanged.edges)
  const newNodes = Array.prototype.concat([], added.nodes, removed.nodes, unchanged.nodes, updated.nodes)
  const newCombos = Array.prototype.concat([], added.combos, removed.combos, unchanged.combos, updated.combos)

  const data = {
    edges: Array.from(new Set(newEdges)),
    nodes: Array.from(new Set(newNodes)),
    combos: Array.from(new Set(newCombos))
  }

  const emptyCombos = data.combos.filter((combo) => {
    const { id } = combo

    const hasNodeChildren = data.nodes.some((node) => node.comboId === id)
    const hasComboChildren = data.combos.some((combo) => combo.parentId === id)

    return !hasNodeChildren && !hasComboChildren
  })

  emptyCombos.forEach((combo) => {
    const { id } = combo
    const emptyPlaceholder = {
      id: id + '-placeholder',
      type: 'text-box',
      itemType: 'node',
      notes: 'empty',
      comboId: id
    }

    data.nodes.push(emptyPlaceholder)
  })

  graph.read(data)

  emptyCombos.forEach((combo) => {
    const emptyPlaceholder = graph.findById(combo.id + '-placeholder').getContainer()
    emptyPlaceholder.hide()
  })

  Array.prototype.concat([], added.edges, added.combos, added.nodes).forEach((item) => {
    const found = graph.findById(item.id)
    if (found) {
      found.setState('added', true)
      // found.update({ added: true }, false)
    }
  })

  Array.prototype.concat([], removed.edges, removed.combos, removed.nodes).forEach((item) => {
    const found = graph.findById(item.id)
    if (found) {
      found.setState('removed', true)
      // found.update({ removed: true }, false)
    }
  })

  Array.prototype.concat([], updated.combos, updated.nodes).forEach((item) => {
    const found = graph.findById(item.id)
    if (found) {
      found.setState('updated', true)
      // found.update({ updated: true }, false)
    }
  })

  return data
}
