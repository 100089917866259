<template>
  <component
    :is="position ? 'o-tooltip' : 'span'"
    :title="position ? '' : title"
    :label="title"
    class="is-relative is-inline-block"
    :class="{ [wrapperClass]: true }"
    :position="position"
    @click="doAction"
  >
    <span :class="{ 'quota-reached': !valid }">
      <slot />
    </span>
  </component>
</template>

<script>
import { mapState } from 'pinia'
import { useAccountStore } from '@/store/account'
import { useUserStore } from '@/store/user'
import QuotaUpgradeModal from '@/components/modal/QuotaUpgrade.vue'
import EventBus from '@/common/eventBus'

export default {
  props: {
    quota: {
      type: [String, Array],
      required: true
    },
    value: {
      type: Number,
      required: false,
      default: -1
    },
    disable: {
      type: Boolean,
      required: false,
      default: false
    },
    position: {
      type: String,
      required: false,
      default: ''
    },
    titleValid: {
      type: String,
      required: true
    },
    titleInvalid: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: false,
      default: ''
    },
    wrapperClass: {
      type: String,
      required: false,
      default: ''
    },
    iconPosition: {
      type: String,
      required: false,
      default: 'right'
    },
    freePricingPlan: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    ...mapState(useAccountStore, ['hasReachedQuota', 'getQuotaLimit']),
    ...mapState(useUserStore, ['getPricingPlan']),

    valid () {
      const { quota, value, freePricingPlan } = this

      if (freePricingPlan) {
        if (this.getPricingPlan === 'free') {
          return false
        }

        return true
      }

      if (Array.isArray(quota)) {
        return quota.every((q) => {
          return !this.hasReachedQuota(q)
        })
      }

      if (value > -1) {
        return value < this.getQuotaLimit(quota)
      }

      return !this.hasReachedQuota(quota)
    },

    title () {
      return this.valid ? this.titleValid : this.titleInvalid
    },

    modalText () {
      return this.text || this.titleInvalid
    }
  },

  methods: {
    doAction () {
      if (this.valid) {
        this.$emit('execute')
      } else {
        this.openModal()
      }
    },

    async openModal () {
      const instance = this.$oruga.modal.open({
        component: QuotaUpgradeModal,
        props: {
          text: this.modalText
        },
        trapFocus: true
      })
      const upgrade = await instance.promise

      if (upgrade === true) {
        EventBus.dispatch('upgradePricingPlan')
      }
    }
  }
}
</script>

<style scoped>
.quota-reached {
  cursor: not-allowed;
  opacity: 0.5;
}
.quota-reached > * {
  pointer-events: none;
}
</style>
