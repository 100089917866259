import instance from '@/api'
import mainServerUrl from '@/api/modules/main-server'

export const projectsApiUrl = mainServerUrl + 'projects'

export default {
  // eslint-disable-next-line camelcase
  getPage (offset, limit, filters) {
    return instance.get(
      projectsApiUrl,
      {
        params: {
          ...filters,
          offset,
          limit
        }
      }
    )
  },
  create (formData) {
    return instance.post(
      projectsApiUrl,
      formData
    )
  },
  get (id) {
    return instance.get(
      `${projectsApiUrl}/${id}`
    )
  },
  getThumbnail (id) {
    return instance.get(
      `${projectsApiUrl}/${id}/thumbnail`
    )
  },
  patch (id, fields) {
    return instance.patch(
      `${projectsApiUrl}/${id}`,
      fields
    )
  },
  put (id, project) {
    return instance.put(
      `${projectsApiUrl}/${id}`,
      project
    )
  },
  delete (id) {
    return instance.delete(
      `${projectsApiUrl}/${id}`
    )
  },
  getHistory (id) {
    return instance.get(
      `${projectsApiUrl}/${id}/history`
    )
  },
  favorite (id) {
    return instance.post(
      `${projectsApiUrl}/${id}/favorite`
    )
  },
  unfavorite (id) {
    return instance.post(
      `${projectsApiUrl}/${id}/unfavorite`
    )
  },
  unyoke_users (id) {
    return instance.post(
      `${projectsApiUrl}/${id}/unyoke`
    )
  },
  accept_invitation (id) {
    return instance.post(
      `${projectsApiUrl}/invitations/${id}`
    )
  },
  decline_invitation (id) {
    return instance.delete(
      `${projectsApiUrl}/invitations/${id}`
    )
  },
  getTemplates () {
    return instance.get('/templates/list.json')
  },
  getTemplate (template) {
    return instance.get(`/templates/${template}/diagram.json`)
  },
  getTemplateThumbnail (template) {
    return instance.get(`/templates/${template}/thumbnail.png`, { responseType: 'blob' })
  }
}
