import axios from 'axios'
import qs from 'qs'

export const instance = axios.create({
  baseURL: '/',
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json'
  },
  paramsSerializer: {
    serialize: (params) => qs.stringify(params, { skipNulls: true, indices: false }) // to get: param=value1&param=value2
  }
})

export const oauthInstance = axios.create({
  baseURL: '/',
  timeout: 10000,
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})

export default instance
