import instance from '@/api'
import mainServerUrl from '@/api/modules/main-server'

export const userApiUrl = mainServerUrl + 'users'

export default {
  getUser (userId) {
    return instance.get(
      `${userApiUrl}/${userId}`,
      {
        cancelToken: null
      }
    )
  },
  patchUser (userId, fields) {
    return instance.patch(
      `${userApiUrl}/${userId}`,
      fields
    )
  },
  deleteUser (userId) {
    return instance.delete(
      `${userApiUrl}/${userId}`
    )
  }
}
