<template>
  <div id="app">
    <o-modal
      :active="calendlyOpen"
      @close="calendlyOpen = false"
    >
      <CalendlyModal @close="calendlyOpen = false" />
    </o-modal>
    <o-modal
      :active="!!importingProjectID"
      @close="importingModalClosed"
    >
      <ImportingProjectModal @close="importingModalClosed" />
    </o-modal>
    <o-modal
      :active="!!syncing"
      @close="setSyncing(null)"
    >
      <SyncingAccountModal @close="setSyncing(null)" />
    </o-modal>
    <o-modal
      :active="github.help"
      @close="github.help = false"
    >
      <GithubHelpModal @close="github.help = false" />
    </o-modal>
    <o-modal :active="github.install">
      <GithubInstallModal />
    </o-modal>
    <NavBar
      v-if="displayNavBar"
      @setTheme="setTheme"
    />
    <o-loading
      :active="isPageLoading"
      :full-page="true"
    />
    <Transition
      mode="out-in"
      name="fade"
    >
      <router-view @setTheme="setTheme" />
    </Transition>
  </div>
</template>

<script>
import EventBus from '@/common/eventBus'
import NavBar from '@/components/NavBar.vue'
import ImportingProjectModal from '@/components/modal/ImportingProject.vue'
import SyncingAccountModal from '@/components/modal/SyncingAccount.vue'
import GithubHelpModal from '@/components/modal/github/GithubHelp.vue'
import GithubInstallModal from '@/components/modal/github/GithubInstall.vue'
import CalendlyModal from '@/components/modal/Calendly.vue'
import { mapActions, mapState } from 'pinia'
import { useAccountStore } from './store/account'
import { useMappingStore } from './store/mapping'
import { useProjectStore } from './store/project'
import { useProviderAccountsStore } from './store/provider-accounts'
import { useGithubStore } from './store/github'
import { useUserStore } from './store/user'

// List of pages where there's no navbar
const NO_NAVBAR_PAGES = [
  'Login',
  'Register',
  'ResetPassword',
  'ChangePassword',
  'ConnectSSO',
  'VerifyAccount',
  'Onboarding',
  'Pricing'
]

export default {
  name: 'App',
  metaInfo: {
    title: 'Home',
    titleTemplate: 'Holori - %s'
  },
  components: {
    NavBar,
    ImportingProjectModal,
    SyncingAccountModal,
    GithubHelpModal,
    GithubInstallModal,
    CalendlyModal
  },
  data () {
    return {
      root: null,
      github: {
        help: false,
        install: false
      },
      isPageLoading: false,
      calendlyOpen: false
    }
  },

  computed: {
    ...mapState(useAccountStore, ['userID']),
    ...mapState(useProjectStore, ['importingProjectID']),
    ...mapState(useProviderAccountsStore, ['syncing']),
    ...mapState(useUserStore, ['getGithubID', 'hasPassedOnboarding']),

    currentPage () {
      // this one is to trigger the showFooter one below
      return this.$route
    },
    displayNavBar () {
      // Check if the current page does not require navbar
      return !NO_NAVBAR_PAGES.includes(this.currentPage.name)
    }
  },

  watch: {
    getGithubID (githubID) {
      if (githubID) {
        this.proceedToGitHubAppInstall()
        this.getGithubUserAccount().then(() => {
          this.getGithubOrganizations()
        })
      }
    },

    '$route.query.connect_github' (val) {
      if (val) {
        EventBus.dispatch('openGithubAppHelp')
        this.$router.replace({ query: null })
      }
    },

    userID (newV) {
      if (newV) {
        this.initUserData(this.userID)
      }
    }
  },

  beforeDestroy () {
    // remove "logout" event handling on exit
    EventBus.remove('logout')
    EventBus.remove('openGithubAppHelp')
    EventBus.remove('openCalendly', this.openCalendly)
  },

  created () {
    this.getProvidersData()
    this.getPricingPlans()

    // Load user data when userID is set
    if (!this.user && this.userID) {
      this.initUserData(this.userID)
    }

    EventBus.on('openCalendly', this.openCalendly)
  },
  mounted () {
    EventBus.on('startGlobalLoading', () => {
      this.isPageLoading = true
    })
    EventBus.on('stopGlobalLoading', () => {
      this.isPageLoading = false
    })
    EventBus.on('logout', (cb) => {
      // listen to logout event
      this.logout(cb)
    })

    EventBus.on('openGithubAppHelp', () => {
      this.github.help = true
    })

    // Use this variable to refer to the page document to gain access to the attributes in order to read/modify it
    this.root = document.documentElement
    this.root.style.setProperty('--is-dark-theme', false)
    this.root.setAttribute('data-theme', 'light')

    this.checkGitHubInstall()
  },
  methods: {
    ...mapActions(useAccountStore, {
      performLogout: 'logout'
    }),
    ...mapActions(useUserStore, ['initUserData']),
    ...mapActions(useMappingStore, ['getProvidersData', 'getAllRegions', 'getPricingPlans']),
    ...mapActions(useGithubStore, {
      getGithubUserAccount: 'getUserAccount',
      getGithubOrganizations: 'getOrganizations'
    }),
    ...mapActions(useProviderAccountsStore, ['setSyncing']),

    logout (query = {}) {
      // perform the actual logout
      this.performLogout().catch(() => { }).finally(
        () => {
          // we go to the home page (~= login) without any redirect
          this.$router.push({
            path: '/',
            query
          }
            // catch to avoid console error due sometimes a redirection conflict not yet identified
            // https://stackoverflow.com/questions/62223195/vue-router-uncaught-in-promise-error-redirected-from-login-to-via-a
          ).catch(() => { })
        }
      )
    },
    setTheme (e) {
      // toggle between dark and light mode
      this.root.setAttribute('data-theme', e ? 'dark' : 'light')
    },
    importingModalClosed () {
      EventBus.dispatch('importingModalClosed')
    },

    checkGitHubInstall () {
      const { installation_id: installationID, setup_action: setupAction } = this.$route.query

      if (setupAction === 'install' && installationID) {
        this.toast({
          variant: 'success',
          message: 'Thank you for installing Holori GitHub App! You should now see your organizations on the left.'
        })

        this.$router.replace({ query: null })
      }
    },

    proceedToGitHubAppInstall () {
      if (this.getGithubID && this.hasPassedOnboarding && window.localStorage.getItem('installGithubApp') === 'true') {
        this.github.install = true
      }
    },

    openCalendly () {
      this.calendlyOpen = true
    }
  }
}
</script>
