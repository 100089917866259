import { parse, stringify } from 'svgson'
import instance from '@/api'

// https://stackoverflow.com/a/30106551
const b64EncodeUnicode = (str) => {
  // first we use encodeURIComponent to get percent-encoded Unicode,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
    function toSolidBytes (_match, p1) {
      return String.fromCharCode('0x' + p1)
    }))
}

export const getImage = (path, size, createImage) => {
  const success = (res) => {
    parse(res.data)
      .then((json) => {
        json.attributes.width = size
        json.attributes.height = size

        const svg = stringify(json)

        createImage(`data:image/svg+xml;base64,${b64EncodeUnicode(svg)}`)
      })
  }

  const fail = () => {
    instance.get('/holori/logo.svg')
      .then((res) => success(res))
  }

  instance.get(`/diagram${path}.svg`)
    .then((res) => success(res))
    .catch(fail)
}
