import providerAccountsApi from '@/api/modules/main-server/provider_accounts'
import { defineStore } from 'pinia'
import { useAccountStore } from './account'

const initialState = {
  providerAccounts: [],
  loadingProviderAccounts: false,
  providerAccount: null,
  sync: {
    loading: false,
    last_sync_date: '',
    last_sync_data: null,
    last_sync_thumbnail: null
  },
  syncHistory: [],
  syncParams: {
    offset: 0,
    sync_date_until: null,
    sync_date_from: null,
    sort_by: 'desc(sync_date)',
    limit: 10,
    total: 0
  },
  syncing: null,
  deleting: null
}

export const useProviderAccountsStore = defineStore('provider-accounts', {
  state: () => initialState,

  getters: {
    mostRecentProviderAccounts: ({ providerAccounts }) => providerAccounts.slice(0, 5),
    getName: ({ providerAccount }) => providerAccount?.name,
    syncLoading: ({ sync }) => sync.loading,
    lastSyncDate: ({ sync }) => sync.last_sync_date,
    lastSyncData: ({ sync }) => sync.last_sync_data,
    lastSyncThumbnail: ({ sync }) => sync.last_sync_thumbnail,
    sortedSyncHistory: ({ syncHistory }) => {
      return syncHistory.sort((a, b) => {
        const timeA = new Date(a.sync_date).getTime()
        const timeB = new Date(b.sync_date).getTime()

        if (timeA < timeB) {
          return 1
        }
        return -1
      })
    },
    getSyncHistoryData: ({ syncHistory }) => (date) => syncHistory.find((entry) => entry.sync_date === date)?.sync_data,
    syncPage: ({ syncParams: { offset, limit } }) => offset / limit + 1
  },

  actions: {
    get (providerID) {
      this.setProviderAccount(null)
      return providerAccountsApi.get(providerID)
        .then(({ data }) => {
          this.providerAccount = data
        })
    },
    getProviderAccounts () {
      this.loadingProviderAccounts = true
      return providerAccountsApi.getPage()
        .then(({ data }) => {
          this.providerAccounts = data.provider_accounts
          this.loadingProviderAccounts = false
        })
    },
    addProviderAccount (payload) {
      return providerAccountsApi.addProviderAccount(payload)
    },
    testProviderAccount (providerID) {
      return providerAccountsApi.verify(providerID)
    },
    updateProviderAccount (payload) {
      return providerAccountsApi.updateProviderAccount(this.providerAccount.id, payload)
    },
    getRegions (providerID) {
      return providerAccountsApi.getRegions(providerID)
    },
    getServices (providerID) {
      return providerAccountsApi.getServices(providerID)
    },
    setDeleting (deleting) {
      this.deleting = deleting
    },
    deleteProviderAccount (providerAccountID) {
      return new Promise((resolve, reject) => {
        providerAccountsApi.deleteProviderAccount(providerAccountID).then(
          () => {
            const accountStore = useAccountStore()
            accountStore.getQuotas()
            resolve()
          },
          (err) => reject(err)
        )
      })
    },
    setProviderAccount (providerAccount) {
      this.providerAccount = providerAccount
    },
    getSync (providerAccountID) {
      this.setSync({
        loading: true,
        last_sync_date: '',
        last_sync_data: null,
        last_sync_thumbnail: null
      })

      return new Promise((resolve, reject) => {
        providerAccountsApi.getSync(providerAccountID).then((res) => {
          const { data } = res

          if (data) {
            this.setSync({
              loading: false,
              ...data
            })
            resolve(this.sync)
          } else {
            reject(new Error('Unable to sync'))
          }
        })
      })
    },
    setSync (data) {
      this.sync = data
    },
    getSyncHistory (providerAccountID, params = {}) {
      this.syncHistory = []
      return new Promise((resolve, reject) => {
        if (providerAccountID === 'demo') {
          this.syncHistory = this.sync.history.map((entry) => {
            const json = JSON.parse(entry)
            return {
              sync_data: json.changes.diagram,
              sync_date: json.date,
              sync_thumbnail: json.changes.thumbnail
            }
          })
          return resolve(this.syncHistory)
        }
        providerAccountsApi.getSyncHistory(providerAccountID, {
          ...this.syncParams,
          ...params
        }).then((res) => {
          const { data, headers } = res

          if (data) {
            this.syncHistory = data.history_entries
            this.syncParams.total = parseInt(headers['content-range'].split('/')[1])
            resolve(this.syncHistory)
          } else {
            reject(new Error('Unable to retrieve sync history'))
          }
        })
      })
    },
    getSyncHistoryEntry (providerAccountID, date) {
      // Trying to get the very latest entry (the one on the account), give it directly
      if (date === this.lastSyncDate) {
        return new Promise((resolve) => {
          const { lastSyncData, lastSyncDate } = this
          resolve({
            sync_date: lastSyncDate,
            sync_data: lastSyncData
          })
        })
      }

      return new Promise((resolve, reject) => {
        if (providerAccountID === 'demo') {
          const entry = this.sync.history.find((entry) => {
            const json = JSON.parse(entry)
            return json.date === date
          })
          if (!entry) {
            reject(new Error('Could not get sync entry'))
          }
          const parsed = JSON.parse(entry)
          resolve({
            sync_data: parsed.changes.diagram,
            sync_date: parsed.date
          })
        } else {
          providerAccountsApi.getSyncHistory(providerAccountID, {
            sync_date_from: date,
            sync_date_until: date
          }).then((res) => {
            const { data } = res

            if (data) {
              const found = data.history_entries.find((entry) => entry.sync_date === date)

              if (found) {
                resolve(found)
              } else {
                reject(new Error('Could not get sync entry'))
              }
            }
          })
        }
      })
    },
    startSync (providerAccountID) {
      return providerAccountsApi.sync(providerAccountID)
    },
    setSyncPage (page) {
      this.syncParams.offset = (page - 1) * this.syncParams.limit
    },
    setSyncDates (from, until) {
      this.syncParams.sync_date_from = from
      this.syncParams.sync_date_until = until
    },
    setSyncing (syncing) {
      this.syncing = syncing
    }
  }
})
