import filtersApi from '@/api/modules/price-server/filters'
import offersApi from '@/api/modules/price-server/offers'
import axios from 'axios'
import { defineStore } from 'pinia'
import { useCompareStore } from './compare'

// initial state
const initialState = {
  offerPage: [],
  offerCount: 0,
  offerRange: [0, 0],
  offerPageSize: 50,
  offerCurrentPage: 1,

  abortControllers: {
    offer_page: null,
    filters: null
  },
  filtersRange: {
    currency: [],
    payment_period: ['hour'],
    price: [0, 0],
    family: [],
    size: [0, 0],
    io: [0, 0],
    bandwidth: [0, 0],
    location: []
  },

  filters: {
    currency: null,
    payment_period: 'hour',
    family: null,
    size: 0,
    io: 0,
    bandwidth: 0,
    location: [],
    company: [],
    tags: []
  },

  sorting: {
    sortBy: 'price',
    sortDir: 'asc'
  }
}

export const useStorageStore = defineStore('storage', {
  state: () => initialState,

  actions: {
    initFilters (queryParams) {
      const initValues = {
        family: null,
        size: 0,
        io: 0,
        bandwidth: 0,
        location: [],
        company: [],
        tags: []
      }

      this.filters = {
        ...initValues,
        ...queryParams
      }
    },
    getFilters (paymentPeriod = initialState.filters.payment_period) {
      const compare = useCompareStore()
      compare.incrementLoading('filters')

      this.abortControllers.filters?.abort()
      this.updateAbortControllers({ filters: new AbortController() })

      return new Promise(
        (resolve, reject) => {
          filtersApi.get(
            '/storage',
            {
              payment_period: paymentPeriod,
              currency: this.filters.currency
            },
            this.abortControllers.filters.signal
          ).then(
            (response) => {
              this.setFiltersRange(response.data)
              resolve()
            },
            (error) => {
              if (!axios.isCancel(error)) {
                console.error(error)
                reject(error)
              }
            }
          ).then(
            () => compare.decrementLoading('filters')
          )
        }
      )
    },
    async updateFilters (filters) {
      this.filters = {
        ...this.filters,
        ...filters
      }

      await this.getFilters()

      if (filters.payment_period) {
        await this.resetPriceRange()
      }
    },
    getOfferPage () {
      const compare = useCompareStore()
      compare.incrementLoading('offers')

      return new Promise(
        (resolve, reject) => {
          this.abortControllers.offer_page?.abort()
          this.updateAbortControllers({ offer_page: new AbortController() })

          offersApi.getPage(
            '/storage',
            (this.offerCurrentPage - 1) * this.offerPageSize,
            this.offerPageSize,
            this.filters,
            this.sorting,
            this.abortControllers.offer_page.signal
          ).then(
            (response) => {
              // extract values
              const contentRangeHeader = response.headers['content-range'].split(' ', 2)[1].split('/')
              const offerCount = +(contentRangeHeader[1])
              const offerRange = contentRangeHeader[0].split('-').map((x) => parseInt(x, 10))

              this.setOfferCount(offerCount)
              this.setOfferRange(offerRange)
              this.setOfferPage(response.data.storage_offers)
              resolve()
            },
            error => {
              if (!axios.isCancel(error)) {
                console.error(error)
                reject(error)
              }
            }
          ).then(
            () => compare.decrementLoading('offers')
          )
        }
      )
    },
    updateOfferPageSize (size) {
      this.setOfferPageSize(size)
      this.getOfferPage()
    },
    updateOfferCurrentPage (page) {
      this.setOfferCurrentPage(page)
      this.getOfferPage()
    },
    resetPriceRange () {
      this.updateFilters({
        min_price: Math.floor(this.filtersRange.price[0]),
        max_price: Math.ceil(this.filtersRange.price[1])
      })
    },
    computeCost (inputs) {
      let cost = 0
      let sizeHour = 0
      let sizeMonth = 0
      let ioMonth = 0
      let bandwidthMonth = 0

      inputs.product?.prices.forEach(
        (price) => {
          switch (price.unit) {
            case 'size_gio_month':
              sizeHour = price.value
              break
            case 'size_gio_hour':
              sizeMonth = price.value
              break
            case 'io_month':
              ioMonth = price.value
              break
            case 'bandwidth_mio_s_month':
              bandwidthMonth = price.value
              break
            default:
              break
          }
        }
      )

      if (inputs.fields.size) {
        if (sizeMonth) {
          cost += inputs.fields.size * sizeMonth
        } else if (sizeHour) {
          cost += inputs.fields.size * sizeHour
        }
      }
      if (inputs.fields.io) {
        if (ioMonth) {
          cost += inputs.fields.io * ioMonth
        }
      }
      if (inputs.fields.bandwidth) {
        if (bandwidthMonth) {
          cost += inputs.fields.bandwidth * bandwidthMonth
        }
      }
      return cost
    },
    updateSort (sort) {
      this.sorting = sort
    },
    setFiltersRange (filtersRange) {
      this.filtersRange = filtersRange
    },
    setOfferCount (offerCount) {
      this.offerCount = offerCount
    },
    setOfferRange (offerRange) {
      this.offerRange = offerRange
    },
    setOfferPage (offerList) {
      this.offerPage = offerList
    },
    setOfferPageSize (offerPageSize) {
      this.offerPageSize = offerPageSize
    },
    setOfferCurrentPage (offerCurrentPage) {
      this.offerCurrentPage = offerCurrentPage
    },
    // Abort Controllers
    updateAbortControllers (controllers) {
      this.abortControllers = {
        ...this.abortControllers,
        ...controllers
      }
    }
  }
})
