<template>
  <div
    class="modal-card"
    style="width: auto"
  >
    <header class="modal-card-head">
      <p class="modal-card-title">
        Delete pteam
      </p>
    </header>
    <div class="modal-card-body">
      Are you sure you want to delete the team
      <strong>
        {{ deleting?.name }}
      </strong>
      ?
    </div>
    <footer class="modal-card-foot">
      <o-button
        variant="primary"
        icon-left="close"
        :disabled="loading"
        @click="closePopup"
      >
        Cancel
      </o-button>
      <o-button
        class="ml-auto"
        variant="danger"
        icon-left="delete"
        :disabled="loading"
        @click="confirmDeletion"
      >
        Confirm deletion
      </o-button>
    </footer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useTeamStore } from '@/store/team'
import { useUserStore } from '@/store/user'

export default {
  data () {
    return {
      loading: false
    }
  },

  computed: {
    ...mapState(useTeamStore, ['deleting'])
  },

  methods: {
    ...mapActions(useTeamStore, ['delete', 'getPage']),
    ...mapActions(useUserStore, ['refresh']),

    confirmDeletion () {
      this.loading = true

      const { id, name } = this.deleting

      this.delete(id).then(() => {
        this.toast({
          message: `Team "${name}" has been deleted.`,
          variant: 'success'
        })
        this.refresh()
      }).then(() => {
        this.loading = false
        this.getPage()
        this.closePopup()

        const thisRoute = this.$route

        // User was on this team's page,
        // Redirect them to the Overview.
        if (thisRoute.name === 'Team' && thisRoute.params.teamID === id) {
          this.$router.replace({ name: 'Overview' })
        }
      })
    },

    closePopup () {
      this.$emit('close')
    }
  }
}
</script>
