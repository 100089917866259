<template>
  <div class="keyboard-shortcuts is-relative is-border-radius-2xl p-5">
    <p class="mb-5">
      Here are some shortcuts you might find useful on this part of the application.
    </p>
    <div class="is-flex is-flex-wrap-wrap">
      <div
        v-for="(shortcut, i) in shortcuts"
        :key="'shorcut' + i"
        class="shortcut is-flex is-align-items-center mb-3"
      >
        <div class="keys is-flex is-align-items-center">
          <kbd
            v-for="key in shortcut.keys"
            :key="key"
            class="key is-border-radius-2xs"
          >
            {{ key }}
          </kbd>
        </div>
        <span class="ml-3">{{ shortcut.action }}</span>
      </div>
    </div>
    <p class="mt-4">
      You can leave this overlay at any time by pressing <kbd class="key is-border-radius-2xs">escape</kbd>
    </p>
  </div>
</template>

<script>
export default {
  data () {
    return {
      projectShortcuts: [
        {
          keys: ['escape'],
          action: 'Unselect'
        },
        {
          keys: ['delete'],
          action: 'Delete selection'
        },
        {
          keys: ['mouse click'],
          action: 'Select an element'
        },
        {
          keys: ['mouse drag'],
          action: 'Move diagram or selection'
        },
        {
          keys: ['mouse right click'],
          action: 'Open context menu'
        },
        {
          keys: ['mouse wheel up'],
          action: 'Zoom in'
        },
        {
          keys: ['mouse wheel down'],
          action: 'Zoom out'
        },
        {
          keys: ['shift', 'mouse drag'],
          action: 'Drag Selection'
        },
        {
          keys: ['alt', 'mouse drag'],
          action: 'Move selection to change group size'
        },
        {
          keys: ['shift', 'mouse left click'],
          action: 'Add or remove element to selection'
        },
        {
          keys: ['ctrl', 'c'],
          action: 'Copy selected elements'
        },
        {
          keys: ['ctrl', 'v'],
          action: 'Paste copied elements'
        },
        {
          keys: ['ctrl', 'shift', 'v'],
          action: 'Paste copied elements without keeping context'
        },
        {
          keys: ['ctrl', 'e'],
          action: 'Create an edge'
        },
        {
          keys: ['ctrl', 'g'],
          action: 'Create a group from the selection'
        },
        {
          keys: ['ctrl', 'd'],
          action: 'Duplicate selection'
        },
        {
          keys: ['ctrl', 'a'],
          action: 'Select all'
        },
        {
          keys: ['ctrl', 'j'],
          action: 'Rearrange diagram'
        },
        {
          keys: ['ctrl', 's'],
          action: 'Save diagram'
        },
        {
          keys: ['ctrl', 'r'],
          action: 'Reload diagram'
        },
        {
          keys: ['ctrl', 'z'],
          action: 'Undo'
        },
        {
          keys: ['ctrl', 'y'],
          action: 'Redo'
        },
        {
          keys: ['tab'],
          action: 'Toggle left sidebar'
        },
        {
          keys: ['ctrl', 'shift', '/'],
          action: 'Toggle right sidebar'
        }
      ],
      dashboardShortcuts: [
        {
          keys: ['ctrl', 'right'],
          action: 'Go to next category'
        }
      ]
    }
  },
  computed: {
    shortcuts () {
      if (this.$route.name.startsWith('Project')) {
        return this.projectShortcuts
      } else {
        return this.dashboardShortcuts
      }
    }
  }
}
</script>

<style scoped>
.keyboard-shortcuts {
  background-color: rgba(0,0,0,0.5);
  border: 2px solid rgba(255,255,255,0.75);
  color: rgba(255,255,255,0.75);
  line-height: 1rem;
}
.shortcut {
  width: 50%;
}
.keys {
  flex: 0 0 210px;
}
.key {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.1em 0.5em;
  margin: 0 0.2em;
  color: var(--text-color);
  box-shadow: 0 1px 0px rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
  background-color: #f7f7f7;
  padding: 0.1em 0.5em;
  margin-right: 2px;
  text-transform: capitalize;
}
</style>
