<template>
  <nav
    class="navbar full-width is-fixed is-top-0"
    role="navigation"
    aria-label="main navigation"
  >
    <o-modal
      :active="openHistory"
      @close="openHistory = false"
    >
      <ProjectHistory @close="openHistory = false" />
    </o-modal>
    <o-modal
      :active="openShareProject"
      @close="openShareProject = false"
    >
      <ShareProject
        :project="project"
        @close="openShareProject = false"
      />
    </o-modal>
    <o-modal
      v-if="canHaveKeyboardShortcuts"
      :active="keyboardShortcutsOpen"
      @close="keyboardShortcutsOpen = false"
    >
      <KeyboardShortcuts />
    </o-modal>
    <o-modal
      :active="deletingProviderAccount !== null"
      @close="setDeletingProviderAccount(null)"
    >
      <DeleteProviderAccount @close="setDeletingProviderAccount(null)" />
    </o-modal>
    <o-modal
      :active="deletingTeam !== null"
      @close="setDeletingTeam(null)"
    >
      <DeleteTeam @close="setDeletingTeam(null)" />
    </o-modal>
    <div
      class="navbar-brand is-flex is-align-items-center full-height is-align-content-center is-flex-shrink-2 ellipsis"
    >
      <a
        :href="getLogoRedirectURL"
        role="link"
        class="navbar-item is-align-items-end"
      >
        <picture>
          <img
            :src="`${publicPath}holori/navbar.svg`"
            alt="Holori Logo"
            width="150"
          >
        </picture>
      </a>
      <!-- Only appears on touch devices -->

      <a
        role="button"
        class="ml-auto navbar-burger"
        :class="{ 'is-active': mobileDropdown }"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasicExample"
        @click="mobileMenuToggle"
      >
        <span aria-hidden="true" />
        <span aria-hidden="true" />
        <span aria-hidden="true" />

        <div
          v-if="mobileDropdown"
          class="dropdown is-active"
        >
          <MobileMenu
            :display-navigation="isProject || isCompare || isProjectDiff"
            :navigation-views="views"
            :navigation-selected-view="selectedView"
            :can-have-keyboard-shortcuts="canHaveKeyboardShortcuts"
            @navigate="chooseView"
          >
            <a
              v-if="isCompare"
              class="dropdown-item is-flex is-align-items-center is-whitespace-normal"
              @click="gotoPage('Projects')"
            >
              <o-icon
                icon="group"
                class="ml-0 mr-2"
              />
              Project Calculator
            </a>

            <template v-if="isProject">
              <a
                v-keyboard-shortcut="'Ctrl+H'"
                class="dropdown-item is-flex is-align-items-center is-whitespace-normal"
                @click="showHistory"
              >
                <o-icon
                  icon="history"
                  class="ml-0 mr-2"
                />
                History
              </a>
              <a
                v-if="project?.user === userID"

                class="dropdown-item is-flex is-align-items-center is-whitespace-normal"
                @click="shareProject"
              >
                <o-icon
                  icon="share-variant-outline"
                  class="ml-0 mr-2"
                />
                Share project
              </a>
              <a
                v-else-if="project?.shared_with?.includes(userID)"
                class="dropdown-item is-flex is-align-items-center is-whitespace-normal"
                @click="leaveProject"
              >
                <o-icon
                  icon="share-off"
                  class="ml-0 mr-2"
                />
                Leave project
              </a>
            </template>
            <a
              v-else-if="!isCompare && !isProviderOffer"
              class="dropdown-item is-flex is-align-items-center is-whitespace-normal"
              @click="gotoPage('Compare')"
            >
              <o-icon
                icon="table-search"
                class="ml-0 mr-2"
              />
              Calculator
            </a>
          </MobileMenu>
        </div>
      </a>
      <!-- ///////////////////////////// -->
    </div>

    <div class="navbar-menu">
      <div class="navbar-end pl-0 is-flex-grow-1">
        <div
          v-if="isProject || isCompare || isProjectDiff || isProviderAccountDiff"
          class="is-absolute full-width is-flex is-justify-content-center is-pointer-events-none"
        >
          <div class="tabs is-pointer-events-all">
            <ul class="is-flex-grow-0">
              <li
                v-for="view in Object.keys(views)"
                :id="'navbar-' + view"
                :key="view"
                :class="{ 'is-active': view.toLowerCase() === selectedView }"
              >
                <a @click="chooseView">
                  <o-icon :icon="views[view]" />
                  <span class="is-size-5">{{ view }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div
          v-else
          class="p-2 horizontal-center is-left-half is-fixed is-size-3 is-flex-grow-2 is-border-radius-md"
        >
          <transition
            v-if="!isCompare"
            name="fade-x"
            mode="out-in"
          >
            <strong :key="pageTitle">
              {{ pageTitle }}
            </strong>
          </transition>
        </div>
        <div
          v-if="isCompare"
          class="navbar-item p-2"
        >
          <o-button
            title="Project calculator"
            variant="primary"
            class="has-text-weight-semibold"
            icon-left="group"
            @click="gotoPage('Projects')"
          >
            Project Calculator
          </o-button>
        </div>
        <div
          v-if="isProject"
          class="is-flex"
        >
          <div class="navbar-item p-2">
            <o-button
              variant="primary"
              title="Project history"
              class="is-border-radius-full"
              icon-left="history"
              @click="showHistory"
            />
          </div>

          <div class="navbar-item p-2 navbar-share-button">
            <o-button
              v-if="project?.user === userID"
              variant="primary"
              title="Share project"
              class="is-border-radius-full"
              icon-left="share-variant-outline"
              @click="shareProject"
            />
            <o-button
              v-else-if="project?.shared_with?.includes(userID)"
              variant="primary"
              title="Leave project"
              class="is-border-radius-full"
              icon-left="share-off"
              @click="leaveProject"
            />
          </div>
        </div>

        <template v-else-if="!isCompare && !isProviderOffer">
          <div class="navbar-item p-2">
            <o-button
              title="Go to calculator"
              class="is-primary has-text-weight-semibold"
              icon-left="table-search"
              @click="$router.push({ name: 'Compare' })"
            >
              Calculator
            </o-button>
          </div>
        </template>

        <template v-if="isProviderOffer">
          <div class="navbar-item p-2">
            <button
              title="Back to product list"
              class="py-2 px-5 button is-border-radius-lg"
              @click="$router.push({ name: 'Compare'})"
            >
              Product List
            </button>
          </div>
        </template>
        <div
          v-if="darkThemeAvailable"
          class="navbar-item p-2"
        >
          <o-button
            variant="primary"
            title="Toggle dark mode"
            class="is-border-radius-full"
            icon-left="theme-light-dark"
            @click="$emit('setTheme', darkTheme = !darkTheme)"
          />
        </div>

        <div class="navbar-item p-2">
          <o-dropdown
            aria-role="list"
            position="bottom-left"
          >
            <template #trigger>
              <button
                title="Help"
                class="button is-primary is-border-radius-full"
              >
                <span class="icon is-small">
                  <o-icon icon="help" />
                </span>
              </button>
            </template>

            <HelpMenu :can-have-keyboard-shortcuts="canHaveKeyboardShortcuts" />
          </o-dropdown>
        </div>

        <div
          v-if="(!isCompare && !isLoggedIn) || isLoggedIn"
          class="navbar-item p-2 is-size-5"
        >
          <UserMenu />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import EventBus from '@/common/eventBus'
import { mapState, mapActions } from 'pinia'
import UserMenu from '@/components/UserMenu.vue'
import MobileMenu from '@/components/MobileMenu.vue'
import HelpMenu from '@/components/HelpMenu.vue'
import ProjectHistory from '@/components/modal/ProjectHistory.vue'
import ShareProject from '@/components/modal/ShareProject.vue'
import KeyboardShortcuts from '@/components/modal/KeyboardShortcuts.vue'
import DeleteProviderAccount from '@/components/modal/DeleteProviderAccount.vue'
import DeleteTeam from '@/components/modal/DeleteTeam.vue'
import { useTeamStore } from '@/store/team'
import { useAccountStore } from '@/store/account'
import { useProjectStore } from '@/store/project'
import { useProviderAccountsStore } from '@/store/provider-accounts'

export default {
  name: 'NavBar',

  components: {
    UserMenu,
    MobileMenu,
    HelpMenu,
    ProjectHistory,
    ShareProject,
    KeyboardShortcuts,
    DeleteProviderAccount,
    DeleteTeam
  },

  data () {
    return {
      customPageTitle: '',
      darkTheme: false,
      darkThemeAvailable: false,
      publicPath: process.env.BASE_URL,
      viewsProject: {
        Design: 'pencil-ruler',
        Estimate: 'calculator',
        Export: 'export-variant'
      },
      viewsProjectDiff: {
        Visualize: 'pencil-ruler',
        Compare: 'compare',
        Diff: 'vector-difference'
      },
      viewsProviderAccountDiff: {
        Visualize: 'pencil-ruler',
        Compare: 'compare',
        Diff: 'vector-difference',
        Export: 'export-variant'
      },
      viewsCompare: {
        Compute: 'calculator',
        Storage: 'database',
        Network: 'network'
      },
      openHistory: false,
      openShareProject: false,
      mobileDropdown: false,
      calendlyOpen: false,
      keyboardShortcutsOpen: false
    }
  },
  computed: {
    ...mapState(useAccountStore, ['userID', 'isLoggedIn']),
    ...mapState(useProjectStore, ['project']),
    ...mapState(useTeamStore, {
      teams: 'teams',
      deletingTeam: 'deleting'
    }),
    ...mapState(useProviderAccountsStore, {
      deletingProviderAccount: 'deleting'
    }),

    pageTitle () {
      return this.$route.meta.title || this.customPageTitle
    },

    selectedView () {
      const { name, params } = this.$route

      if (this.isProjectDiff) {
        return name?.replace('ProjectDiff', '').toLowerCase()
      } else if (this.isProviderAccountDiff) {
        return name?.replace('ProviderAccountDiff', '').toLowerCase()
      } else if (this.isProject) {
        return name?.replace('Project', '').toLowerCase()
      } else {
        const { tab } = params

        return tab || 'compute'
      }
    },
    isProject () {
      const { name } = this.$route
      return name && name !== 'Projects' && name.startsWith('Project')
    },
    isProjectDiff () {
      const { name } = this.$route
      return name && name.startsWith('ProjectDiff')
    },
    isProviderAccountDiff () {
      const { name } = this.$route
      return name && name.startsWith('ProviderAccountDiff')
    },
    isProjects () {
      return this.$route.name === 'Projects'
    },
    isCompare () {
      return this.$route.name === 'Compare'
    },
    isProviderOffer () {
      return this.$route.name === 'ProviderOffer'
    },
    views () {
      if (this.isCompare) {
        return this.viewsCompare
      } else if (this.isProjectDiff) {
        return this.viewsProjectDiff
      } else if (this.isProviderAccountDiff) {
        return this.viewsProviderAccountDiff
      } else if (this.isProject) {
        return this.viewsProject
      }
      return {}
    },
    canHaveKeyboardShortcuts () {
      return this.isProject || this.isProjects
    },
    getLogoRedirectURL () {
      return this.isLoggedIn
        ? this.$router.resolve({ name: 'Overview' }).href
        : '/'
    }
  },
  created () {
    EventBus.on('setPageTitle', this.updatePageTitle)
    EventBus.on('openKeyboardShortcuts', this.openKeyboardShortcuts)
    EventBus.on('openHistory', this.showHistory)
  },
  beforeDestroy () {
    EventBus.remove('setPageTitle', this.updatePageTitle)
    EventBus.remove('openKeyboardShortcuts', this.openKeyboardShortcuts)
    EventBus.remove('openHistory', this.showHistory)
  },
  methods: {
    ...mapActions(useTeamStore, {
      setDeletingTeam: 'setDeleting',
      getPage: 'getPage'
    }),
    ...mapActions(useProviderAccountsStore, {
      setDeletingProviderAccount: 'setDeleting'
    }),
    ...mapActions(useProjectStore, ['declineInvitation']),

    updatePageTitle (title) {
      this.customPageTitle = title
    },
    logout () {
      EventBus.dispatch('logout')
    },
    mobileMenuToggle () {
      this.mobileDropdown = !this.mobileDropdown
    },
    showHistory () {
      this.openHistory = true
    },
    openKeyboardShortcuts () {
      this.keyboardShortcutsOpen = true
    },
    exportProj () {
      // Set view to Export
      this.$router.push({
        name: 'ProjectExport',
        params: {
          id: this.$route.params.id
        }
      }).catch(() => {})
    },
    shareProject () {
      this.getPage()
      this.openShareProject = true
    },
    async leaveProject () {
      await this.declineInvitation(this.project.id)
      this.gotoPage('Projects')
    },
    gotoPage (name) {
      this.$router.push({ name }).catch(() => {})
    },
    chooseView (e) {
      // Set view
      const tab = e.target.innerText
      let route = {}

      if (this.isCompare) {
        route = {
          name: 'Compare',
          params: {
            tab: tab.toLowerCase()
          }
        }
      } else if (this.isProjectDiff) {
        route = {
          name: `ProjectDiff${tab}`
        }
      } else if (this.isProviderAccountDiff) {
        const query = this.$route.query

        route = {
          name: `ProviderAccountDiff${tab}`,
          query
        }
      } else if (this.isProject) {
        route = {
          name: `Project${tab}`
        }
      }

      this.$router.push(route).catch(() => {})
    },
    resetZoom () {
      EventBus.dispatch('resetZoom')
    },
    gotoDesign () {
      if (this.selectedView !== 'Design') {
        this.gotoPage('ProjectDesign')
      }
    },
    goToTeam (teamID) {
      this.$router.push({
        name: 'Team',
        params: {
          teamID
        }
      }).catch(() => {})
    },
    showDiagramFullscreen () {
      // if not in Design view, move to it
      this.gotoDesign()

      this.$nextTick(() => EventBus.dispatch('showDiagramFullscreen'))
    },
    saveDiagramImage (imageType) {
      // if not in Design view, move to it
      this.gotoDesign()

      this.$nextTick(() => {
        if (imageType === 'svg') {
          EventBus.dispatch('exportSvg')
        } else {
          EventBus.dispatch('exportImage')
        }
      })
    }
  }
}
</script>

<style scoped>
nav {
  background-color: var(--navbar-bg);
  border-bottom: 1px solid var(--navbar-border);
  height: var(--navbar-height);
  box-shadow: 0 2px 5px lightgray;
}

/* unset logo max height */
.navbar-item img {
  max-height: unset;
}

/* Project name on navbar */
.min-w-150 {
  min-width: 150px;
}

.navbar-brand div {
  margin: auto;
  min-width: 100px;
}

/* Used to set navbar title text in the middle*/
.horizontal-center {
  transform: translate(-50%);
}

@media screen and (max-width: 1200px) {
  .horizontal-center-project {
    left: auto;
    right: 435px;
    transform: translate(0);
  }
}

/* Navbar search */
.field {
  width: 300px;
}

/* Hamburger related components */
.burger-button {
  width: 60px;
  height: 60px;
  border: none;
  background-color: transparent;
}

#dropdown-menu {
  top: 3.75rem;
  right: 0.3rem;
  left: auto;
  min-width: 150px;
  max-width: 200px;
  box-shadow: 0 0.25rem 0.75rem #CCCCCC;
  animation: fadeIn ease-out 0.3s;
}
#dropdown-menu :deep() span.icon {
  display: inline-flex;
  position: static;
  transform: none;
  height: 1.5rem;
  background: transparent;
  width: 1.5rem;
}
.navbar-share-button button {
  column-gap: 0.5rem;
}

.tabs ul {
  border-bottom-color: transparent;
}
.tabs a {
  border-bottom-color: transparent;
  border-bottom-width: 3px;
  height: var(--navbar-height);
}
.tabs .icon {
  pointer-events: none;
}

/* Dropdown menu */
.o-drop__menu a:hover {
  /* TODO */
  color: #9071f3;
  /* Random color */
}

hr {
  border-top: 1px solid #EEEEEE;
  /* Random color */
  height: 1px;
}

input[type="checkbox"] {
  height: 16px;
  width: 16px;
}

.unsaved {
  position: relative;
}
.unsaved:before {
  content: '';
  display: block;
  position: absolute;
  top: 15px;
  right: 5px;
  background-color: var(--primary);
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.help-menu-item {
  display: flex;
}
</style>
