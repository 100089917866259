import companiesApi from '@/api/modules/price-server/companies'
import offersApi from '@/api/modules/price-server/offers'
import { defineStore } from 'pinia'

const initialState = {
  company: null,
  offers: [],
  loaded: {},
  offer: null,
  locationName: '',
  companyOffers: []
}

export const useProviderStore = defineStore('provider', {
  state: () => initialState,

  getters: {
    getLocations: (state) => state.offers.map((offer) => offer.location),
    getOfferFromLocation: (state) => (location) => state.offers.find(offer => offer.location === location),
    getOffersNames: (state) => Array.from(new Set(state.companyOffers.map((offer) => offer.name))),
    getOffersLocations: (state) => (name) => {
      // filters company offers by the name from the parameter
      const offers = state.companyOffers.filter(offer => offer.name === name)
      // gets only locations from the offers above
      return offers.map((offer) => offer.location)
    },
    getProductDescription: (state) => (offerName) => {
      const offer = state.companyOffers.find(offer => offer.name === offerName)
      return offer.description
    }
  },

  actions: {
    // Gets the list of providers. Saves the provider thats in param. Updates it in data.
    async fetchProvidersInfo (provider) {
      await companiesApi.get().then(({ data }) => {
        const { companies } = data
        const company = companies.find(obj => { return obj.name === provider })
        if (company !== undefined) {
          this.company = company
        }
      })
    },
    // Gets offers based on company id and product name.
    fetchProviderOffers (productName) {
      const companyID = this.company.id
      return offersApi.getOffers(companyID, productName)
        .then(({ data }) => {
          this.offers = data.offers
        })
    },
    // Checks if the offer is already cached and displays it, and if not - sends a request to get offer.
    fetchOfferById (offerID) {
      if (offerID in this.loaded) {
        this.offer = this.loaded[offerID]
      } else {
        offersApi.get('/compute/', offerID).then(({ data }) => {
          this.loaded[data.id] = data
          this.offer = data
        })
      }
    },
    fetchProviderOffersAll (companyID) {
      return offersApi.getOffers(companyID, null).then(({ data }) => {
        this.companyOffers = data.offers
      })
    }
  }
})
