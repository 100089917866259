<template>
  <div class="modal-card mx-auto">
    <header class="modal-card-head">
      <p class="modal-card-title">
        Upgrade your account
      </p>
      <o-button
        icon-left="close"
        class="ml-auto pl-4"
        @click="$emit('close')"
      />
    </header>
    <section class="modal-card-body">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="text" />
      <p>
        You can upgrade your account to a superior plan by clicking the button below:
      </p>
      <QuotaUpgradeButton @click="upgrade" />
    </section>
  </div>
</template>

<script>
import QuotaUpgradeButton from '@/components/QuotaUpgradeButton.vue'

export default {
  name: 'QuotaUpgrade',

  components: {
    QuotaUpgradeButton
  },

  props: {
    text: {
      type: String,
      required: true
    }
  },

  methods: {
    upgrade () {
      this.$emit('close', true)
    }
  }
}
</script>
