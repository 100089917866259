<template>
  <o-dropdown
    aria-role="list"
    title="Your account"
    position="bottom-left"
  >
    <template #trigger>
      <button class="button is-primary is-border-radius-full">
        <span class="icon is-small icon-user">
          <div class="is-size-4"> {{ userFirstLetter }} </div>
        </span>
      </button>
    </template>

    <o-dropdown-item
      aria-role="listitem"
      class="is-flex is-align-items-center has-font-weight-bold"
      title="Your account"
      @click="$router.push({ name: 'Account' })"
    >
      <o-icon
        icon="account"
        class="ml-0 mr-2"
      />
      {{ username }}
    </o-dropdown-item>

    <o-dropdown-item
      aria-role="listitem"
      class="is-flex is-align-items-center"
      title="Check your quotas"
      @click="gotoQuotas"
    >
      <o-icon
        icon="apps-box"
        class="ml-0 mr-2"
      />
      Quotas
    </o-dropdown-item>

    <o-dropdown-item
      aria-role="listitem"
      class="is-flex is-align-items-center"
      title="Upgrade your plan"
      @click="upgradePlan"
    >
      <o-icon
        icon="arrow-up-bold-hexagon-outline"
        class="ml-0 mr-2"
      />
      Upgrade
    </o-dropdown-item>

    <hr class="my-1">

    <o-dropdown-item
      aria-role="listitem"
      class="is-flex is-align-items-center"
      title="Sign out"
      @click="logout"
    >
      <o-icon
        icon="logout"
        class="ml-0 mr-2"
      />
      Sign out
    </o-dropdown-item>
    <!-- TODO other item for this drop down -->
  </o-dropdown>
</template>

<script>
import { mapState } from 'pinia'
import EventBus from '@/common/eventBus'
import accountApi from '@/api/modules/main-server/account'
import { useUserStore } from '@/store/user'

export default {
  computed: {
    ...mapState(useUserStore, ['user', 'getPricingPlan', 'isStripeCustomer']),

    username () {
      return this.user?.name
    },
    userFirstLetter () {
      return this.user?.name.charAt(0).toUpperCase()
    }
  },
  created () {
    EventBus.on('upgradePricingPlan', this.upgradePlan)
  },
  beforeDestroy () {
    EventBus.remove('upgradePricingPlan')
  },
  methods: {
    upgradePlan (newTab) {
      if (this.isStripeCustomer) {
        EventBus.dispatch('startGlobalLoading')
        accountApi.stripePortal()
          .then((res) => {
            EventBus.dispatch('stopGlobalLoading')
            location.href = res.data.url
          })
      } else {
        const route = { name: 'UpgradePricingPlan' }
        if (newTab) {
          const { href } = this.$router.resolve(route)
          window.open(href, '_blank')
        } else {
          this.$router.push(route).catch(() => {})
        }
      }
    },
    gotoQuotas () {
      this.$router.push({ name: 'Quotas' }).catch(() => {})
    },
    logout () {
      EventBus.dispatch('logout')
    }
  }
}
</script>
