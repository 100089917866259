import axiosInstance from '@/api'
import EventBus from '@/common/eventBus'
import accountApi from '@/api/modules/main-server/account'
import { useAccountStore } from '@/store/account'
import { isCancel } from 'axios'

export default function setup () {
  const accountStore = useAccountStore()

  axiosInstance.interceptors.request.use(
    (config) => {
      const accessToken = accountStore.getAccessToken
      if (accessToken) {
        // inject the token in request headers
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      if (config.cancelToken !== null) {
        const controller = new AbortController()
        config.signal = controller.signal

        window.abortControllers[config.url] = controller
      }

      return config
    },
    (error) => Promise.reject(error)
  )

  const removeSignal = (url) => {
    delete window.abortControllers[url]
  }

  axiosInstance.interceptors.response.use(
    (result) => {
      removeSignal(result.config.url)
      return result
    },
    (error) => {
      removeSignal(error.config.url)

      if (isCancel(error)) {
        return Promise.reject(error)
      }

      const originalConfig = error.config || {}

      // trying to refresh token if possible
      // Access Token was maybe expired

      // set a flag to not loop infinitely
      originalConfig._retry = true

      if (error?.response == null) {
        // not an API error
        return Promise.reject(error)
      }

      if (error.response.status !== 401) {
        // not an API authentication error
        return Promise.reject(error)
      }

      if (originalConfig.url === accountApi.loginUrl) {
        // error during a login or a token refresh attempt
        //  attempt a proper logout in case it is needed
        EventBus.dispatch('logout')
        return Promise.reject(error)
      }

      if (originalConfig._retry) {
        // an attempt to refresh the token was already made and failed.
        //  so from there, we know we are not logged in anymore
        return Promise.reject(error)
      }

      accountStore.renewToken().then(
        () => {
          // continue and retry now that we got a fresh token
          return Promise.resolve(axiosInstance(originalConfig))
        },
        (refreshError) => {
          // user might have been disconnected
          //   send the logout event through the entire app
          EventBus.dispatch('logout')
          return Promise.reject(refreshError)
        }
      )
    }
  )
}
