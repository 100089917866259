import { instance } from '@/api'
import providerServerUrl from '@/api/modules/provider-server'

export default {
  import (providerAccountID, projectID, regions, serviceOptions) {
    return instance.post(
      providerServerUrl + 'provider_accounts/' + providerAccountID + '/import',
      {
        project_id: projectID,
        regions,
        services_options: serviceOptions
      }
    )
  },
  /**
   * Terraform import from an uploaded *.zip file
   * possible return codes:
   *  - 422 :
   *     - invalid form data
   *     - invalid file name suffix (must end with ".zip")
   *     - invalid file content type (must be "application/zip")
   * @param {*} projectID id of the project to import into
   * @param {*} file upload this file to import it
   * @returns query promise
   */
  uploadTerraformFile (projectID, file) {
    const formData = new FormData()
    formData.append('project_id', projectID)
    formData.append('uploaded_file', file)
    return instance.post(
      providerServerUrl + 'terraform/import',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  }
}
