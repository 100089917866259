import instance from '@/api'
import priceServerUrl from '@/api/modules/price-server'

export const companiesApiUrl = priceServerUrl + 'companies'

export default {
  get () {
    return instance.get(
      companiesApiUrl
    )
  }
}
