import mappingApi from '@/api/modules/public/mapping/mapping'
import offersApi from '@/api/modules/price-server/offers'
import { defineStore } from 'pinia'

export const useMappingStore = defineStore('mapping', {
  state: () => ({
    mapping: {},
    providersData: {},
    ssoProviders: [],
    pricingPlans: [],
    regions: [],
    regionsReady: false,
    offers: {}
  }),

  getters: {
    findPricingPlan: (state) => (stripeID) => state.pricingPlans.find((plan) => plan.stripe_id === stripeID),

    getRegions: (state) => state.regions.sort(
      (a, b) => {
        if (a.provider === 'aws' && b.provider !== 'aws') {
          return -1
        } else if (a.provider === 'azure' && !['aws', 'azure'].includes(b.provider)) {
          return -1
        } else if (a.provider === 'gcp' && ['aws', 'azure', 'gcp'].includes(b.provider)) {
          return -1
        }

        return 1
      }),

    /**
     * "Converts" a provider version of location, to a Holori version of location.
     * @param {Object} state
     * @returns {String} The corresponding value, or <empty string> if not found.
     */
    findHoloriValueForLocation: ({ regions }) => ({ location, provider }) => {
      if (!location || !provider) {
        return ''
      }

      const regionsForProvider = regions.find((reg) => reg.provider === provider)

      if (!regionsForProvider) {
        return ''
      }

      const foundRegion = regionsForProvider.regions.find((region) => region.providerValue === location)

      if (!foundRegion) {
        return ''
      }

      return foundRegion.holoriValue
    },

    getHoloriValues: ({ regions }) => regions.map((obj) => obj.regions.map(({ holoriValue }) => holoriValue)).reduce((prev, curr) => prev.concat(curr), [])
  },

  actions: {
    /**
     * Loads the providers data from the JSON file.
     */
    getProvidersData () {
      return new Promise((resolve) => {
        mappingApi.get('providers.json').then(({ data }) => {
          this.providersData = data
          resolve(data)
        })
      })
    },

    /**
     * Loads the SSO providers from the JSON file.
     */
    getSSOProviders () {
      return mappingApi.get('sso.json').then(({ data }) => {
        this.ssoProviders = data
      })
    },

    getPricingPlans () {
      mappingApi.getPublic('pricing_plans.json').then(({ data }) => {
        this.pricingPlans = data
      })
    },

    getAllRegions (providers) {
      this.regions = []
      this.regionsReady = false

      const promises = providers.map(({ name: providerName }) => {
        return this.get(`${providerName}/configuration.json`).then((data) => {
          const regions = Object.keys(data.regions).map((providerValue) => ({
            holoriValue: data.regions[providerValue],
            providerValue: isNaN(providerValue) ? providerValue : data.regions[providerValue],
            providerName // We need to repeat it to store it on the location group later.
          })).sort((a, b) => a.providerValue < b.providerValue ? -1 : 1)

          this.regions.push({
            provider: providerName,
            regions
          })
        })
      })

      return Promise.all(promises).then(() => {
        this.regionsReady = true
      })
    },

    /**
     * Retrieves a JSON file from the mapping part.
     * @param {String} url The JSON path to retrieve.
     * @returns {Object} The JSON data you wanted.
     */
    get (url) {
      return new Promise((resolve) => {
        const found = this.mapping[url]

        if (found) {
          resolve(found)
          return
        }

        mappingApi.get(url).then(({ data }) => {
          this.mapping[url] = data
          resolve(data)
        })
      })
    },

    /**
     * Retrieves a list of JSON files from the mapping part, and gives the merge of all of them.
     * @param {Array<String>} urls The list of JSON paths to retrieve.
     * @returns {Object} The merge of all JSON data you wanted.
     */
    getAll (urls, merge = true) {
      return new Promise((resolve) => {
        Promise.all(urls.map(this.get)).then(() => {
          let data = {}

          urls.forEach((url) => {
            if (merge) {
              data = {
                ...data,
                ...this.mapping[url]
              }
            } else {
              data = {
                ...data,
                [url]: this.mapping[url]
              }
            }
          })

          resolve(data)
        })
      })
    },

    getOffers (companyID, productName, type, location, filters) {
      const key = JSON.stringify({
        companyID,
        productName,
        type,
        location,
        ...filters
      })

      return new Promise((resolve, reject) => {
        const found = this.offers[key]

        if (found) {
          resolve(found)
          return
        }

        offersApi.getOffers(companyID, productName, type, location === '' ? undefined : location, {
          ...filters,
          limit: 1
        }).then(({ data }) => {
          const product = type === 'compute' ? data.offers[0] : data.storage_offers[0]

          if (!product) {
            return reject(new Error('No product found'))
          }

          const result = {
            ...product,
            product_type: type
          }

          this.offers[key] = result

          resolve(result)
        })
      })
    }
  }
})
