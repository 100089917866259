<template>
  <a
    ref="link"
    :href="getSSOURL(provider)"
    class="button px-2 is-flex is-align-items-center is-justify-content-center is-border-radius-md"
    @click="$emit('click', $event)"
  >
    <picture class="is-flex mr-3">
      <source
        type="image/avif"
        :srcset="`/companies/${provider.id}/logo.avif`"
      >
      <img
        :src="`/companies/${provider.id}/logo.png`"
        :alt="`${provider.name}`"
      >
    </picture>
    {{ provider.name }}
  </a>
</template>

<script>
import { mapActions } from 'pinia'
import { useAccountStore } from '@/store/account'

export default {
  props: {
    provider: {
      type: Object,
      required: true
    }
  },

  methods: {
    ...mapActions(useAccountStore, ['getAuthorizeCodeSSOURL']),

    getSSOURL (provider) {
      return this.getAuthorizeCodeSSOURL({
        provider: provider.id,
        destination: this.$router.currentRoute.query.redirect
      })
    },

    login () {
      this.$refs.link.click()
    }
  }
}
</script>

<style scoped>
.button picture {
  height: 30px;
  width: 30px;
}
</style>
