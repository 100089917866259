<template>
  <div
    class="modal-card"
    style="width: auto"
  >
    <header class="modal-card-head">
      <p class="modal-card-title">
        Share project {{ project.name }}
      </p>
      <o-button
        icon-left="close"
        class="ml-auto pl-4"
        @click="closePopup"
      />
    </header>
    <section class="modal-card-body">
      <h3 class="has-text-weight-bold mb-2">
        Individual user sharing
      </h3>

      <p
        v-if="!isAnythingLoading && shared_with.length < 1"
        class="mb-2"
      >
        This project is not shared to any users
      </p>

      <o-field
        v-for="user, index in shared_with"
        :key="user.id"
        class="mb-2"
      >
        <o-input
          v-model="user.email"
          expanded
          disabled
          type="email"
        />
        <o-button
          icon-left="close-circle-outline"
          @click="shared_with.splice(index, 1)"
        />
      </o-field>

      <o-field
        v-for="_email, index in invitations"
        :key="index"
        class="mb-2"
      >
        <o-input
          v-model="invitations[index]"
          expanded
          type="email"
          placeholder="email to invite"
        />
        <o-button
          icon-left="close-circle-outline"
          @click="invitations.splice(index, 1)"
        />
      </o-field>

      <QuotaFeature
        quota="project_shared_with"
        :value="shared_with.length + invitations.length"
        title-valid="Add another invitation"
        title-invalid="You cannot create an invitation to this project because you reached your maximum shared quota."
        wrapper-class="full-width"
        icon-position="top-right"
        @execute="createInvitation"
      >
        <o-button
          variant="primary"
          class="mt-2 full-width"
          icon-left="account-multiple-plus"
        >
          Add invitation
        </o-button>
      </QuotaFeature>

      <template v-if="hasTeams">
        <hr>
        <o-field label="Share to a team">
          <o-select
            v-model="shared_with_team"
            placeholder="select a team to share with"
            expanded
          >
            <option
              v-if="shared_with_team"
              :value="null"
            >
              Remove team sharing
            </option>
            <optgroup label="Teams">
              <option
                v-for="team in teams"
                :key="team.id"
                :value="team.id"
              >
                {{ team.name }}
              </option>
              <option
                v-if="teams.length < 1"
                disabled
              >
                No team
              </option>
            </optgroup>
          </o-select>
        </o-field>
      </template>

      <o-loading
        :active="isAnythingLoading > 0"
        :full-page="false"
      />
    </section>
    <footer class="modal-card-foot">
      <o-button
        variant="danger"
        icon-left="cancel"
        :disabled="saveLoading"
        @click="closePopup"
      >
        Cancel
      </o-button>
      <o-button
        class="ml-auto"
        icon-left="content-save"
        variant="primary"
        :disabled="saveLoading"
        @click="saveSharing"
      >
        Save
      </o-button>
    </footer>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useTeamStore } from '@/store/team'
import { useProjectStore } from '@/store/project'
import { useUserStore } from '@/store/user'
import QuotaFeature from '@/components/QuotaFeature.vue'

export default {
  name: 'ShareProjectPopup',
  components: {
    QuotaFeature
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      // todo
      invitations: [],
      shared_with: [],
      shared_with_team: null,
      error: null,
      userLoading: 0,
      saveLoading: false
    }
  },
  computed: {
    ...mapState(useTeamStore, ['teams', 'isLoading', 'hasTeams']),

    isFormValid () {
      return this.team_name !== ''
    },
    isAnythingLoading () {
      return this.userLoading > 0 || this.isLoading
    }
  },
  mounted () {
    this.invitations = [...this.project.invitations]

    this.shared_with_team = this.project.shared_with_team

    for (const userID of this.project.shared_with) {
      this.userLoading += 1
      this.getUser(userID)
    }
  },
  methods: {
    ...mapActions(useProjectStore, ['patch', 'getPage']),
    ...mapActions(useUserStore, ['get']),

    async saveSharing () {
      this.error = null
      this.saveLoading = true

      const formData = {
        id: this.project.id,
        shared_with_team: this.shared_with_team,
        invitations: this.invitations.filter(email => email !== '')
      }

      if (this.invitations !== this.project.invitations) { // TODO better list changes detection
        await this.patch(formData)
          .then(() => {
            this.toast({
              message: `Project "${this.project.name}" has been shared successfully!`,
              variant: 'success'
            })
          })
          .catch(
            (error) => {
              this.error = error
            }
          )
      }

      if (this.error) {
        this.saveLoading = false
        return
      }

      const unyokeUsers = []
      const shareWithIds = this.shared_with.map(member => member.id)
      for (const userID of this.project.shared_with) {
        if (!shareWithIds.includes(userID)) {
          unyokeUsers.push(userID)
        }
      }
      if (unyokeUsers.length > 0) {
        await this.unyokeUsers({ id: this.project.id, unyokeUsers }).catch(
          (error) => {
            this.error = error
          }
        )
      }

      if (this.error) {
        this.saveLoading = false
        return
      }

      this.saveLoading = false
      this.getPage()
      this.closePopup()
    },
    async getUser (userID) {
      const response = await this.get(userID)
      this.shared_with.push(response.data)
      this.userLoading -= 1
    },
    closePopup () {
      this.$emit('close')
    },
    createInvitation () {
      this.invitations.push('')
    }
  }
}
</script>

<style scoped>
  /* TODO */
</style>
