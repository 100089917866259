import { Buffer } from 'buffer'
import Vue from 'vue'
import App from '@/App.vue'
import pinia from '@/store'
import Oruga from '@oruga-ui/oruga'
import { bulmaConfig } from '@oruga-ui/theme-bulma'

import './oruga.customize.scss'
import './holori.customize.scss'
import './css/main.css'
import './css/mdi.css'
import 'simple-syntax-highlighter/dist/sshpre.css'

import 'vue-slider-component/theme/material.css'

import router from '@/router'

import setup from '@/api/interceptor'
import { mixins } from '@/common/mixins'
import keyboardShortcutDirective from '@/common/keyboard-shortcut-directive'

// Needed for animated GIFs
window.Buffer = Buffer
window.abortControllers = {}

const config = Object.assign(bulmaConfig, {
  modal: {
    ...bulmaConfig.modal,
    canCancel: ['escape', 'outside', 'button']
  }
})

Vue.use(Oruga, config)
Vue.use(pinia)
Vue.directive('keyboard-shortcut', keyboardShortcutDirective)
setup()

Vue.mixin(mixins)

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
