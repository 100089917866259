import githubApi from '@/api/modules/main-server/github'
import { defineStore } from 'pinia'

export const useGithubStore = defineStore('github', {
  state: () => ({
    account: null,
    organizations: [],
    repositories: {},
    repositoriesSearch: '',
    pullRequests: {},
    pullRequestsSearch: '',
    pullRequestsPage: 1,
    pullRequestsTotal: 10,
    abortControllers: {
      repositories: null,
      pullRequests: null,
      branches: null
    }
  }),

  getters: {
    getOrganizationByName: ({ organizations }) => (organizationName) => organizations.find(({ login }) => login === organizationName),
    getOrganizationIDByName () {
      return (organizationName) => this.getOrganizationByName(organizationName)?.id
    },
    getOrganizationInstallationIDByName () {
      return (organizationName) => this.getOrganizationByName(organizationName)?.installationID
    },
    getRepositories: ({ repositories }) => Object.values(repositories),
    getFilteredRepositories: ({ getRepositories, repositoriesSearch }) => {
      const search = repositoriesSearch.toLowerCase()

      return getRepositories.filter((repository) => {
        if (!search) {
          return true
        }

        const { name } = repository
        return name.toLowerCase().includes(search)
      })
    },
    getPullRequests: ({ pullRequests }) => Object.values(pullRequests),

    /**
     * Returns the filtered pull requests, sorted by update date.
     * @param {Object} state
     * @returns {Array<Object>}
     */
    getFilteredPullRequests: ({ getPullRequests, pullRequestsSearch }) => {
      const search = pullRequestsSearch.toLowerCase()

      return getPullRequests.filter((pullRequest) => {
        if (!search) {
          return true
        }

        const { commit, message, author: { name: authorName }, number } = pullRequest.info
        const concat = [
          commit,
          message,
          authorName,
          number
        ].join(' ').toLowerCase()

        return concat.includes(search)
      }).sort((prA, prB) => prA.updated > prB.updated ? -1 : 1)
    }
  },

  actions: {
    reset () {
      this.repositories = {}
      this.pullRequests = {}
    },

    /**
     * Retrieve the user account.
     * @returns {Promise}
     */
    getUserAccount () {
      this.account = null

      return new Promise((resolve, _reject) => {
        githubApi.getUserAccount().then(({ data }) => {
          this.account = data
          resolve(data)
        })
      })
    },

    /**
     * Retrieve the list of the organizations to which the logged user has access and on which the Holori app is installed.
     * @returns {Promise}
     */
    getOrganizations () {
      this.organizations = []

      return new Promise((resolve, _reject) => {
        githubApi.getInstalledOrganizations(this.account.id).then((organizations) => {
          this.organizations = organizations.sort((a, _b) => a.type === 'User' ? -1 : 1)
          resolve(organizations)
        })
      })
    },

    /**
     * Retrieve information about an organization.
     * @param {String} organization The name of the organization to retrieve.
     * @returns {Promise}
     */
    getOrganization (organization) {
      return new Promise((resolve, reject) => {
        githubApi.getOrganization(organization).then(({ data }) => {
          resolve(data)
        }).catch(reject)
      })
    },

    /**
     * Get the all the available repositories for an organization.
     * @param {String} organization The name of the organization (not case sensitive).
     * @returns {Promise}
     */
    getRepositoriesForOrganization (organization) {
      this.repositories = {}
      this.abortControllers.repositories?.abort()

      return new Promise((resolve, reject) => {
        this.abortControllers.repositories = new AbortController()

        githubApi.getRepositoriesForOrganization(organization, this.abortControllers.repositories.signal).then(({ data }) => {
          const promises = data.map((repo) => {
            const { name, html_url: url } = repo

            const installationID = this.getOrganizationInstallationIDByName(organization)

            if (installationID) {
              return githubApi.getProjectsForName(installationID, `${organization}/${name}`, this.abortControllers.repositories.signal, 1).then((res) => {
                const nbDiagrams = +res.headers['content-range'].split('/').pop()

                this.repositories = {
                  ...this.repositories,
                  [name]: {
                    name,
                    url,
                    status: '', // TODO
                    nbDiagrams,
                    latestPR: null
                  }
                }

                this.getLatestPullRequest(organization, name)
                  .catch((err) => {
                    this.removeRepository(name)
                    reject(err)
                  })
              }, () => {})
            }

            return null
          })

          Promise.all(promises).then(() => resolve())
        }).catch(() => reject(new Error('Could not get list of repositories.')))
      })
    },

    /**
     * Remove a repository from the list (repository is unavailable).
     * @param {String} repositoryName The name of the repository to remove from the list.
     */
    removeRepository (repositoryName) {
      const newRepositories = { ...this.repositories }
      delete newRepositories[repositoryName]
      this.repositories = newRepositories
    },

    /**
     * Get the latest pull request for a repository, and save its number in the repository information.
     * @param {String} organization The name of the organization (not case sensitive).
     * @param {String} repository The name of the repository (not case sensitive).
     * @returns {Promise}
     */
    getLatestPullRequest (organization, repository) {
      return new Promise((resolve, reject) => {
        githubApi.getPullRequestsForRepository(organization, repository, null, 1, 1).then(({ data }) => {
          const pullRequest = data[0]

          // If there is a pull request...
          if (pullRequest) {
            // ...add its number to the repository information.
            this.updateRepository(repository, {
              latestPR: pullRequest.number,
              latestPRUrl: pullRequest.html_url
            })
          }

          resolve()
        }).catch(() => reject(new Error('Could not get latest pull request.')))
      })
    },

    /**
     * Get all the pull requests for a repository.
     * @param {String} organization The name of the organization (not case sensitive).
     * @param {String} repository The name of the repository (not case sensitive).
     * @returns {Promise}
     */
    getPullRequestsForRepository (organization, repository) {
      this.pullRequests = {}
      this.abortControllers.pullRequests?.abort()

      return new Promise((resolve, reject) => {
        this.abortControllers.pullRequests = new AbortController()

        githubApi.getPullRequestsForRepository(organization, repository, this.abortControllers.pullRequests.signal, this.pullRequestsPage)
          .then(({ data, headers: { link } }) => {
            // GitHub sends a "link" header in which there are links for previous/next/last page.
            // We need just the last page to determine how many pages are available.
            if (link) {
              const match = link.match(/<https:\/\/api\.github\.com\/repositories\/\d+\/pulls\?page=(\d+)&per_page=\d+>; rel="last"/)

              if (match) {
                const lastPage = parseInt(match[1])
                this.pullRequestsTotal = lastPage * 10
              }
            } else {
              this.pullRequestsTotal = 10
            }

            data.forEach((pullRequest) => {
              const { number, html_url: url } = pullRequest

              const commit = pullRequest.head.sha.substr(0, 7)
              const info = {
                commit,
                message: null,
                author: {
                  name: pullRequest.user.login
                },
                number: pullRequest.number
              }

              // Append the pull request to the list.
              this.pullRequests = {
                ...this.pullRequests,
                [number]: {
                  lastDiagram: '2 days ago', // TODO
                  info,
                  url,
                  changes: null,
                  updated: new Date(pullRequest.updated_at).getTime()
                }
              }

              // Download the latest commit information for this pull request.
              githubApi.getCommit(organization, repository, commit, this.abortControllers.pullRequests.signal).then(({ data }) => {
                const { commit: { message }, stats: { additions, deletions } } = data

                this.updatePullRequest(number, {
                  info: {
                    ...info,
                    message
                  },
                  changes: {
                    additions,
                    deletions
                  }
                })
              }).catch(() => reject(new Error('Could not get commit information.')))

              const organizationObj = this.getOrganizationByName(organization)

              if (organizationObj) {
                // Get the first project that matches the name filter, to link it to the pull request row.
                const name = `${organization}/${repository} - ${number} -`
                const { installationID } = organizationObj
                githubApi.getProjectsForName(installationID, name, this.abortControllers.pullRequests.signal, 1).then(
                  ({ data: { projects } }) => {
                    const [project] = projects

                    if (project) {
                      // Send the filter to the table, so it already knows what to search.
                      this.updatePullRequest(number, {
                        projects: {
                          name,
                          installationID
                        }
                      })
                    } else {
                      this.updatePullRequest(number, { projects: null })
                    }
                  }, () => {})
              }
            })

            resolve()
          }).catch((e) => {
            reject(new Error('Could not get pull requests for repository.'))
          })
      })
    },

    /**
     * Patch a repository information.
     * @param {String} repository The name of the repository to update.
     * @param {Object} update The object representing the update to patch the repository.
     */
    updateRepository (repository, update) {
      this.repositories = {
        ...this.repositories,
        [repository]: {
          ...this.repositories[repository],
          ...update
        }
      }
    },

    /**
     * Patch a pull request information.
     * @param {Number} pullRequestNumber The number of the pull request to update.
     * @param {Object} update The object representing the update to patch the pull request.
     */
    updatePullRequest (pullRequestNumber, update) {
      this.pullRequests = {
        ...this.pullRequests,
        [pullRequestNumber]: {
          ...this.pullRequests[pullRequestNumber],
          ...update
        }
      }
    },

    setRepositoriesSearch (repositoriesSearch) {
      this.repositoriesSearch = repositoriesSearch
    },

    setPullRequestsSearch (pullRequestsSearch) {
      this.pullRequestsSearch = pullRequestsSearch
    },

    setPullRequestsPage (pullRequestsPage) {
      this.pullRequestsPage = pullRequestsPage
    },

    // For Import from GitHub

    /**
     * Get the all the available repositories for an organization, but simple version.
     * @param {String} organization The name of the organization (not case sensitive).
     * @returns {Promise<AxiosResponse>}
     */
    getRepositoriesList (organization) {
      this.abortControllers.repositories?.abort()
      this.abortControllers.repositories = new AbortController()

      return githubApi.getRepositoriesForOrganization(organization, this.abortControllers.repositories.signal)
    },

    /**
     * Get the all the available branches for a repository.
     * @param {String} organization The name of the organization (not case sensitive).
     * @returns {Promise<AxiosResponse>}
     */
    getBranchesList (organization, repository) {
      this.abortControllers.branches?.abort()
      this.abortControllers.branches = new AbortController()

      return githubApi.getBranchesForRepository(organization, repository, this.abortControllers.branches.signal)
    },

    importFromZIP (projectID, organization, repository, branch) {
      return githubApi.importFromZIP(projectID, organization, repository, branch)
    }
  }
})
