import terraformImportApi from '@/api/modules/public/mapping/terraform'
import companiesApi from '@/api/modules/price-server/companies'
import { MIGRATION_VERSION } from '@/common/diagram-migrations'
import { defineStore } from 'pinia'
import { useUserStore } from './user'
import { useProjectStore } from './project'

const originalData = {
  combos: [],
  edges: [],
  nodes: [],
  version: MIGRATION_VERSION
}

export const originalSelection = {
  combos: [],
  edges: [],
  nodes: []
}

const initialState = {
  data: originalData,
  selection: originalSelection,
  uniqueSelection: {
    combo: null,
    edge: null,
    node: null
  },
  addingNodePath: '',
  canUndo: false,
  canRedo: false,
  saving: false,
  saved: true,
  undoSize: 0,
  redoSize: 0,
  terraformFields: {},
  locations: [],
  companies: [],
  detailsTab: 'general',
  layouting: false,
  comparing: false,
  comparingRow: null,
  updatedTerraformKeys: {},
  settings: {}
}

export const useDiagramStore = defineStore('diagram', {
  state: () => initialState,

  getters: {
    getNodes: ({ data: { nodes } }) => nodes,
    getCombos: ({ data: { combos } }) => combos,
    getEdges: ({ data: { edges } }) => edges,
    getData: (state) => state.data,
    getGlobalSelection: ({ selection: { combos, edges, nodes } }) => ([...combos, ...edges, ...nodes]),
    isSelected () {
      return (id) => this.getGlobalSelection.find((item) => item.id === id) !== undefined
    },
    findById: ({ data: { combos, nodes } }) => (id) => {
      const data = [...combos, ...nodes]
      let found = null

      for (const item of data) {
        if (item.id === id) {
          found = item
          break
        }
      }

      return found
    },
    /**
     * Returns the total length of the selection.
     * @param {Object} state
     * @returns
     */
    getGlobalSelectionCount () {
      return this.getGlobalSelection.length
    },
    hasUniqueSelection: ({ uniqueSelection: { combo, edge, node } }) => (!!combo || !!edge || !!node),
    getSelectedCombo ({ uniqueSelection, selection }) {
      if (!this.hasUniqueSelection) {
        return selection.combos[0]
      }
      return uniqueSelection.combo
    },
    getSelectedEdge ({ uniqueSelection, selection }) {
      if (!this.hasUniqueSelection) {
        return selection.edges[0]
      }
      return uniqueSelection.edge
    },
    getSelectedNode ({ uniqueSelection, selection }) {
      if (!this.hasUniqueSelection) {
        return selection.nodes[0]
      }
      return uniqueSelection.node
    },
    getSelectedItem () {
      return this.getSelectedNode || this.getSelectedCombo
    },
    getSelectedAnything () {
      return this.getSelectedEdge || this.getSelectedItem
    },
    getItemTerraform: (state) => (id, type) => (state.data[type + 's'].find((node) => node.id === id).terraform),
    getUsedLocations: (state) => state.data.combos.filter((combo) => combo.location !== undefined).map((combo) => combo.location),
    canLeave: (state) => state.undoSize === 0 && state.saved && !state.saving,
    getElementsNumber: ({ data: { nodes, combos, edges } }) => (combos.length + edges.length + nodes.length),
    hasReachedMaximumElementsNumber () {
      const user = useUserStore()
      return this.getElementsNumber >= user.getQuota('project_diagram_element')
    },
    isComparing: (state) => state.comparing,
    getUpdatedTerraformKey: (state) => (id, key) => {
      const forID = state.updatedTerraformKeys[id]
      if (forID) {
        const hasValue = key in forID
        if (hasValue) {
          const val = forID[key]

          // Previous value was undefined, return an empty string.
          if (val === undefined) {
            return '<no value>'
          }

          return val
        }
      }
      return undefined
    }
  },

  actions: {
    setSelection (selection) {
      this.selection = selection
    },
    clearSelection () {
      this.selection = originalSelection
    },
    setUniqueSelection (selection) {
      this.uniqueSelection = selection
    },
    clearUniqueSelection () {
      this.uniqueSelection = {
        combo: null,
        edge: null,
        node: null
      }
    },
    saveData (data) {
      this.saveOriginalData(data)
      this.setSaved(false)
    },
    saveOriginalData (data) {
      this.data = JSON.parse(data)
    },
    setSaved (saved) {
      this.saved = saved
    },
    reset () {
      this.data = originalData
    },
    saveDiagram (thumbnail) {
      return new Promise((resolve, reject) => {
        const diagram = JSON.stringify(this.data)

        this.saving = true

        const project = useProjectStore()

        const projectChanges = {
          ...project.project,
          diagram,
          thumbnail,
          importing: null // reset import flag when saving a new diagram to the server
        }
        const savedProject = project.savedProject

        const changes = { id: project.project.id }

        for (const key in projectChanges) {
          if (projectChanges[key] !== savedProject[key]) {
            changes[key] = projectChanges[key]
          }
        }

        if (Object.keys(changes).length === 1) {
          return
        }

        this.setSaved(true)

        if (changes.importing === false) {
          delete changes.importing
        }

        project.patch(changes)
          .then(() => {
            this.saving = false
            resolve()
          })
          .catch(reject)
      })
    },
    setCanUndo (canUndo) {
      this.canUndo = canUndo
    },
    setCanRedo (canRedo) {
      this.canRedo = canRedo
    },
    setUndoSize (undoSize) {
      this.undoSize = undoSize
    },
    setRedoSize (redoSize) {
      this.redoSize = redoSize
    },
    setAddingNodePath (path) {
      this.addingNodePath = path
    },
    getTerraformFields (path) {
      if (!(path in this.terraformFields)) {
        this.fetchTerraformFields(path)
      }
    },
    fetchTerraformFields (path) {
      terraformImportApi.get(path)
        .then(({ data }) => {
          this.setTerraformFields({
            path,
            fields: data
          })
        })
    },
    setLocations (locations) {
      this.locations = locations
    },
    loadCompanies () {
      return new Promise(
        (resolve) => {
          companiesApi.get().then(
            ({ data }) => {
              this.setCompanies(data.companies)
              resolve(data.companies)
            },
            () => {},
            () => { resolve(this.companies) }
          )
        }
      )
    },
    setTerraformFields (payload) {
      this.terraformFields = {
        ...this.terraformFields,
        [payload.path]: payload.fields
      }
    },
    setCompanies (companies) {
      this.companies = companies
    },
    setDetailsTab (tab) {
      this.detailsTab = tab
    },
    setLayouting (layouting) {
      this.layouting = layouting
    },
    setComparing (comparing) {
      this.comparing = comparing

      if (!comparing) {
        this.cleanUpdatedTerraformKeys()
      }
    },
    setComparingRow (comparingRow) {
      this.comparingRow = comparingRow
    },
    cleanUpdatedTerraformKeys () {
      this.updatedTerraformKeys = []
    },
    addUpdatedTerraformKey ({ id, key, value }) {
      this.updatedTerraformKeys[id] = {
        ...this.updatedTerraformKeys[id],
        [key]: value
      }
    },
    setSettings (settings) {
      this.settings = settings
    }
  }
})
