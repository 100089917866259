import teamsApi from '@/api/modules/main-server/teams'
import { defineStore } from 'pinia'
import { useUserStore } from './user'
import { useAccountStore } from './account'

const initialState = {
  isLoading: 0,

  // Autosave
  autosave: true,
  autosaveDelay: 30,

  // Teams paging
  teams: [],
  currentPage: 1,
  count: 0,
  range: [0, 0],
  pageSize: 21,
  pageLabel: 'Recent',
  filters: {
    name: ''
  },

  team: null,

  deleting: null
}

export const useTeamStore = defineStore('team', {
  state: () => initialState,

  getters: {
    getTeamByID: (state) => (teamID) => state.teams.find((team) => team.id === teamID),
    hasTeams: (state) => state.teams.length > 0,
    isTeamOwner (state) {
      const userStore = useUserStore()
      return this.team?.creator === userStore.getUserID
    },
    isOwnerOfTeam (state) {
      const userStore = useUserStore()

      return (teamID) => {
        const team = this.getTeamByID(teamID)
        if (!team) {
          return false
        }
        return team.creator === userStore.getUserID
      }
    }
  },

  actions: {
    create (formData) {
      return teamsApi.create(formData)
    },
    edit (team) {
      return new Promise(
        (resolve, reject) => {
          teamsApi.put(team.id, team).then(
            ({ data }) => {
              this.setTeam(data)
              resolve()
            },
            (error) => reject(error)
          )
        }
      )
    },
    patch (team) {
      return new Promise(
        (resolve, reject) => {
          teamsApi.patch(team.id, team).then(
            ({ data }) => {
              this.setTeam(data)
              resolve()
            },
            (error) => reject(error)
          )
        }
      )
    },
    delete (teamID) {
      return new Promise(
        (resolve, reject) => {
          teamsApi.delete(teamID).then(
            () => {
              this.changePage(this.currentPage)
              const accountStore = useAccountStore()
              accountStore.getQuotas()
              resolve()
            },
            (error) => reject(error)
          )
        }
      )
    },
    unyokeUsers ({ id, unyokeUsers }) {
      return new Promise(
        (resolve, reject) => {
          teamsApi.unyoke_users(id, { users: unyokeUsers }).then(
            ({ data }) => {
              this.setTeam(data)
              resolve()
            },
            (error) => reject(error)
          )
        }
      )
    },
    acceptInvitation (teamID) {
      return new Promise(
        (resolve, reject) => {
          teamsApi.accept_invitation(teamID).then(
            ({ data }) => {
              this.setTeam(data)
              resolve()
            },
            (error) => reject(error)
          )
        }
      )
    },
    declineInvitation (teamID) {
      return new Promise(
        (resolve, reject) => {
          teamsApi.decline_invitation(teamID).then(
            ({ data }) => {
              this.setTeam(data)
              resolve()
            },
            (error) => reject(error)
          )
        }
      )
    },
    // Reset filter and current page (new search or new category from left menu)
    getFilteredPage (data) {
      this.setCurrentPage(1)
      this.setFilters(data)
      this.getPage()
    },
    // Change page
    changePage (page) {
      this.setCurrentPage(page)
      this.getPage()
    },
    // Get new page
    getPage () {
      this.incrementIsLoading()
      const offset = (this.currentPage - 1) * this.pageSize
      teamsApi.getPage(offset, this.pageSize, this.filters).then(
        ({ headers, data }) => {
          // extract values
          const contentRangeHeader = headers['content-range'].split(' ', 2)[1].split('/')
          const count = +(contentRangeHeader[1])
          const range = contentRangeHeader[0].split('-').map((x) => parseInt(x, 10))
          // commit changes
          this.setCount(count)
          this.setRange(range)
          this.setTeams(data.teams)
        },
        (error) => {
          console.error(error)
          this.setTeams([])
        }
      ).then(
        () => this.decrementIsLoading()
      )
    },
    refreshTeam (id) {
      teamsApi.get(id).then(
        ({ data }) => {
          this.teams = this.teams.map(p => p.id === data.id ? data : p)
        }
      )
    },
    get (id) {
      // immutable approach to trigger vuejs change
      this.setTeam(null)
      this.incrementIsLoading()
      return new Promise(
        (resolve, reject) => {
          teamsApi.get(id).then(
            (response) => {
              this.setTeam(response.data)
              resolve(response)
            },
            (error) => {
              this.setTeam(null)
              reject(error)
            }
          ).then(
            () => this.decrementIsLoading()
          )
        }
      )
    },

    // TODO accept invitation = join
    // TODO decline invitation or left the team

    setDeleting (deleting) {
      this.deleting = deleting
    },

    setTeam (team) {
      this.team = team
    },
    setTeams (teams) {
      this.teams = teams
    },
    // paging
    setPageSize (pageSize) {
      this.pageSize = pageSize
    },
    setCurrentPage (currentPage) {
      this.currentPage = currentPage
    },
    setRange (range) {
      this.range = range
    },
    setCount (count) {
      this.count = count
    },
    // loading
    incrementIsLoading () {
      this.isLoading++
    },
    decrementIsLoading () {
      this.isLoading--
    },
    setFilters (item) {
      this.filters = {
        ...item
      }
    }
  }
})
