import { defineStore } from 'pinia'
import userApi from '@/api/modules/main-server/users'
import { useAccountStore } from './account'
import { useMappingStore } from './mapping'

export const useUserStore = defineStore('user', {
  state: () => ({
    user: null,
    loadingUser: false
  }),

  getters: {
    getUserID: (state) => state.user?.id,

    /**
     * Returns the user's quota for a certain quota.
     * @param {Object} state
     * @returns {Number} The quota or `Infinity` if no quota defined.
     */
    getQuota: (state) => (quota) => {
      const value = state.user?.[`maximum_${quota}_number`]
      return value === null ? Infinity : value
    },

    /**
     * Returns the user's quota value (i.e. number of projects) for a certain quota.
     * @param {Object} state
     * @returns {Number} The value for the quota or `0` if no value.
     */
    getQuotaValue: (state) => (quota) => state.user?.[`${quota}_number`] || 0,

    /**
     * Returns wether the user has reached their limit for a certain quota.
     * @param {Object} state
     * @returns {Boolean} `true` if the quota has been reached, `false` otherwise.
     */
    hasReachedQuota () {
      return (quota) => this.getQuotaValue(quota) >= this.getQuota(quota)
    },

    getPricingPlan: (state) => state.user?.pricing_plan,

    /**
     * Returns the user's current pricing plan in lower case.
     * @param {Object} state
     * @returns {String} The pricing plan in lower case.
     */
    getPricingPlanName: (state) => {
      const mappingStore = useMappingStore()
      return mappingStore.findPricingPlan(state.user?.pricing_plan)?.name
    },

    /**
     * Returns the user's name.
     */
    getUsername: (state) => state.user?.name,

    /**
     * Returns `true` if the user has linked a Stripe account, `false` otherwise.
     * @param {Object} state
     * @returns {Boolean} Wether the user has a Stripe account linked.
     */
    isStripeCustomer: (state) => state.user?.stripe_customer_id !== null,

    /** Getters for Git providers. */
    getGithubID: ({ user }) => user?.github_id,
    getGitlabID: ({ user }) => user?.gitlab_id,
    getBitbucketID: ({ user }) => user?.bitbucket_id,

    hasPassedOnboarding: ({ user }) => user?.first_login_date !== null
  },

  actions: {
    /**
     * Get the data of an user.
     * @param {Number} userID User ID to fetch.
     * @returns Promise
     */
    get (userID) {
      return userApi.getUser(userID)
    },

    /**
     * Refresh the data of the currently logged in user.
     */
    refresh () {
      if (this.user) {
        this.get(this.user.id)
          .then((response) => {
            this.setUser(response.data)
          })
      }
    },

    /**
     * Init the data of a user.
     * @param {Number} userID The ID of the user to init data.
     * @param {Function|undefined} callback The callback to execute after the data is fetched.
     * @returns {Promise}
     */
    initUserData (userID, callback = () => {}) {
      const accountStore = useAccountStore()
      if (this.loadingUser || !accountStore.isLoggedIn) {
        return new Promise((resolve) => {
          resolve()
        })
      }

      this.setLoadingUser(true)

      return new Promise((resolve, reject) => {
        this.get(userID).then(({ data }) => {
          this.setUser(data)
          callback(data)
          resolve(data)
        },
        (error) => {
          reject(error)
        }).then(() => {
          this.setLoadingUser(false)
        })
      })
    },

    /**
     * Patch a user.
     * @param {Object} user The user to patch.
     * @returns {Promise}
     */
    patch (user) {
      return new Promise(
        (resolve, reject) => {
          userApi.patchUser(user.id, user).then(
            (response) => {
              this.setUser(response.data)
              resolve()
            },
            (error) => reject(error)
          )
        }
      )
    },

    /**
     * Update the user's first login information (name, job title and company).
     * @param {Object} param0 The data obtained from the onboarding page.
     * @returns {Promise}
     */
    setFirstLogin ({ user, ...firstLoginData }) {
      return new Promise(
        (resolve, reject) => {
          this.patch({
            id: user.id,
            first_login: true,
            ...firstLoginData
          }).then(
            () => resolve(),
            (error) => reject(error)
          )
        }
      )
    },

    /**
     * Update the provider tokens of a user.
     * @param {Object} user The user object.
     * @returns {Promise}
     */
    updateProviderTokens (user) {
      return new Promise(
        (resolve, reject) => {
          this.patch({
            id: user.id,
            provider_tokens: user.provider_tokens
          }).then(
            () => resolve(),
            (error) => reject(error)
          )
        }
      )
    },
    /**
     * Deletes a user with their ID.
     * @param {Number} userID The user ID to delete.
     * @returns Promise
     */
    delete (userID) {
      return userApi.deleteUser(userID)
    },

    /**
     * Sets the user in the store.
     * @param {Object|null} user The user to set.
     */
    setUser (user) {
      this.user = user
    },

    /**
     * Sets loading status.
     * @param {Boolean} loading Wether the user is loading or not.
     */
    setLoadingUser (loading) {
      this.loadingUser = loading
    }
  }
})
