import instance from '@/api'
import priceServerUrl from '@/api/modules/price-server'

export const filtersApiUrl = priceServerUrl + 'filters'

export default {
  get (type, params, signal) {
    return instance.get(
      filtersApiUrl + type,
      {
        params,
        signal
      }
    )
  }
}
