import { getImage } from '@/common/diagram-options/nodes/images'

const getType = (node) => {
  if (node.text) {
    return 'text-box'
  }

  return 'custom'
}

const makeNodes = (nodes) =>
  nodes.map((node) => ({
    ...node,
    type: getType(node),
    comboId: Array.isArray(node.groups) ? node.groups[0] : ''
  }))

const makeEdges = (edges) =>
  edges.map((edge) => ({
    ...edge,
    source: edge.from,
    target: edge.to
  }))

const makeCombos = (groups) => (
  Object.values(groups)
    .map((group) => {
      const groupNodes = Array.isArray(group.nodes) ? group.nodes : []
      const groupChildren = Array.isArray(group.children) ? group.children : []
      const children = [
        ...groupNodes,
        ...groupChildren
      ].map((o) => typeof o === typeof {} ? o.id : o)

      const ret = {
        ...group,
        nodes: groupNodes,
        children,
        type: 'group'
      }

      if (Array.isArray(group.parents)) {
        ret.parentId = group.parents[0]
      }

      return ret
    })
    .sort((a, b) => a.nodes.length + a.children.length < b.nodes.length + b.children.length ? -1 : 1)
)

const importDiagram = (data) => {
  const nodes = makeNodes(data.nodes)
  const edges = makeEdges(data.edges)
  const combos = makeCombos(data.groups || {})

  return {
    nodes,
    edges,
    combos,
    version: data.version || 0
  }
}

export default (data, updateProgress) => {
  Object.assign(data, importDiagram(data))

  for (let i = 0; i < data.combos.length; i++) {
    const combo = data.combos[i]

    if (combo.nodeBond) {
      let nodeIndex
      const bondNode = data.nodes.find((node, index) => {
        if (node.id === combo.nodeBond) {
          nodeIndex = index
          return true
        }
        return false
      })

      if (!bondNode) {
        delete data.combos[i].nodeBond
        return
      }

      const id = combo.id

      // Prevent weird "self-parent" from a group.
      delete bondNode.comboId

      data.combos[i] = {
        ...combo,
        ...bondNode,
        id,
        type: 'group'
      }

      delete data.combos[i].nodeBond
      delete data.combos[i].groupBond

      data.nodes.splice(nodeIndex, 1)
    }
  }

  // Now, add the images to the nodes.
  const nodesWithImages = data.nodes.filter((node) => node.path)
  const combosWithImages = data.combos.filter((combo) => combo.path)
  const itemsWithImages = nodesWithImages.concat(combosWithImages)

  let counter = 0
  const howMany = itemsWithImages.length

  const update = () => updateProgress(howMany, counter, data)

  // Call update at least once: Maybe there's nothing to do.
  update()

  for (let i = 0; i < data.combos.length; i++) {
    if (data.combos[i].path) {
      getImage(data.combos[i].path, 30, (image) => {
        counter++
        data.combos[i].image = image
        update()
      })
    }
  }

  for (let i = 0; i < data.nodes.length; i++) {
    if (data.nodes[i].path) {
      getImage(data.nodes[i].path, 50, (image) => {
        counter++
        data.nodes[i].image = image
        update()
      })
    }
  }
}
