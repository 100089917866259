import { instance, oauthInstance } from '@/api'
import mainServerUrl from '@/api/modules/main-server'

export const accountApiUrl = mainServerUrl + 'account/'

// this one need to be exported because it is used in the interceptor
export const loginUrl = mainServerUrl + 'token'

export default {
  register (params) {
    return instance.post(
      accountApiUrl + 'register',
      params
    )
  },
  resetPassword (params) {
    return instance.post(
      accountApiUrl + 'reset_password',
      params
    )
  },
  changePassword (params) {
    return instance.put(
      accountApiUrl + 'password',
      params
    )
  },
  changePasswordFromToken (params) {
    return instance.post(
      accountApiUrl + 'password',
      params
    )
  },
  verify (params) {
    return instance.post(
      accountApiUrl + 'verify',
      params
    )
  },
  login (formData) {
    return oauthInstance.post(
      loginUrl,
      formData
    )
  },
  logout () {
    // clears the app session given correct refresh token is already in it
    return instance.post(
      `${loginUrl}/logout`
    )
  },
  getAuthorizeCodeSSOURL (provider) {
    return `/${loginUrl}/login/${provider}`
  },
  authorizeSSO (provider, code, state) {
    return oauthInstance.post(
      `${loginUrl}/${provider}/authorize?code=${code}${state ? `&state=${state}` : ''}`
    )
  },
  renewToken () {
    return instance.get(loginUrl)
  },
  changeEmail (token) {
    return instance.post(
      accountApiUrl + 'new_email',
      token
    )
  },
  getQuotas () {
    return instance.get(accountApiUrl + 'quotas')
  },
  stripeCheckout (priceName, period) {
    return instance.post(
      accountApiUrl + 'stripe_checkout',
      {
        plan_name: priceName,
        period
      }
    )
  },
  stripePortal () {
    return instance.post(accountApiUrl + 'stripe_portal')
  }
}
