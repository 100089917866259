import instance from '@/api'
import priceServerUrl from '@/api/modules/price-server'

export const tagsApiUrl = priceServerUrl + 'tags'

export default {
  get () {
    return instance.get(
      tagsApiUrl
    )
  }
}
