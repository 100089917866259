import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import './account'
import './compare-compute'
import './compare-storage'
import './compare'
import './diagram'
import './mapping'
import './project'
import './provider-accounts'
import './provider'
import './team'
import './user'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

export default pinia
