import instance from '@/api'
import mappingUrl from '@/api/modules/public/mapping'

export const terraformApiUrl = mappingUrl + 'terraform/'

export default {
  get (path) {
    return instance.get(
      terraformApiUrl + path
    )
  }
}
