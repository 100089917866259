import companiesApi from '@/api/modules/price-server/companies'
import tagsApi from '@/api/modules/price-server/tags'
import { defineStore } from 'pinia'
import { useMappingStore } from './mapping'

export const useCompareStore = defineStore('compare', {
  state: () => ({
    loading: {
      filters: 0,
      offers: 0
    },

    companies: [],
    tags: []
  }),

  getters: {
    companyFromID: (state) => (companyID) => state.companies.find(company => company.id === companyID),
    companyFromName: (state) => (companyName) => companyName ? state.companies.find(company => company.name === companyName) : undefined,
    providerName () {
      const mappingStore = useMappingStore()
      return (providerID) => mappingStore.providersData[this.companyFromID(providerID)?.name]?.name
    }
  },

  actions: {
    getTags () {
      this.incrementLoading('filters')

      return new Promise(
        (resolve, reject) => {
          tagsApi.get().then(
            (response) => {
              this.setTags(response.data.tags)
              resolve()
            },
            (error) => {
              console.error(error)
              reject(error)
            }
          ).then(
            () => this.decrementLoading('filters')
          )
        }
      )
    },

    getCompanies () {
      this.incrementLoading('filters')

      return new Promise(
        (resolve, reject) => {
          companiesApi.get().then(
            (response) => {
              for (const company of response.data.companies) {
                this.addCompany(company)
              }
              resolve()
            },
            (error) => {
              console.error(error)
              reject(error)
            }
          ).then(
            () => this.decrementLoading('filters')
          )
        }
      )
    },

    addCompany (company) {
      if (!this.companies.map(company => company.id).includes(company.id)) {
        this.companies.push(company)
      }
    },
    setTags (tags) {
      this.tags = tags
    },
    incrementLoading (type) {
      this.loading[type]++
    },
    decrementLoading (type) {
      this.loading[type]--
    }
  }
})
