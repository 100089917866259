import Vue from 'vue'
import VueRouter from 'vue-router'
import EventBus from '@/common/eventBus'
import { makeTitle } from '@/common/meta'
import { useAccountStore } from '@/store/account'

Vue.use(VueRouter)

const routes = [
  {
    path: '/offers/:providerName/:productName',
    name: 'ProviderOffer',
    component: () => import(/* webpackChunkName: "provider" */ '../views/ProviderOffer.vue'),
    props: true,
    meta: {
      title: 'Offers'
    }
  },
  {
    path: '/offers/:providerName',
    name: 'Provider',
    component: () => import(/* webpackChunkName: "provider" */ '../views/Provider.vue'),
    props: true,
    meta: {
      title: 'Provider'
    }
  },
  {
    path: '/compare/:tab?',
    name: 'Compare',
    props: (route) => ({
      tab: route.params.tab || 'compute'
    }),
    component: () => import(/* webpackChunkName: "compare" */ '../views/Compare.vue'),
    meta: {
      title: 'Compare'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    alias: ['/'],
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/authorize/:provider',
    name: 'Authorize',
    component: () => import(/* webpackChunkName: "authorize" */ '../views/Authorize.vue'),
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      title: 'Register'
    }
  },
  {
    path: '/verifyAccount',
    name: 'VerifyAccount',
    component: () => import(/* webpackChunkName: "verifyAccount" */ '../views/VerifyAccount.vue'),
    meta: {
      title: 'Verify Account'
    }
  },
  {
    path: '/verifyEmail',
    name: 'VerifyEmail',
    component: () => import(/* webpackChunkName: "verifyEmail" */ '../views/VerifyEmail.vue'),
    meta: {
      title: 'Verify Email'
    }
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "resetPassword" */ '../views/ResetPassword.vue'),
    meta: {
      title: 'Reset Password'
    }
  },
  {
    path: '/changePassword',
    name: 'ChangePassword',
    component: () => import(/* webpackChunkName: "changePassword" */ '../views/ChangePassword.vue'),
    meta: {
      title: 'Change Password'
    }
  },
  {
    path: '/welcome',
    name: 'Onboarding',
    component: () => import(/* webpackChunkName: "onboarding" */ '../views/Onboarding.vue'),
    meta: {
      title: 'Onboarding'
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue'),
    meta: {
      title: 'My Account'
    }
  },
  {
    path: '/dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    children: [
      {
        path: '',
        name: 'Overview',
        component: () => import(/* webpackChunkName: "overview" */ '../views/Overview.vue'),
        meta: {
          title: 'Home'
        }
      },
      {
        path: 'import',
        name: 'Import',
        component: () => import(/* webpackChunkName: "import" */ '../views/Import.vue'),
        meta: {
          title: 'Import'
        }
      },
      {
        path: 'projects/new/:type?/:template?',
        name: 'NewProject',
        component: () => import(/* webpackChunkName: "new-project" */ '../views/NewProject.vue'),
        props: true,
        meta: {
          title: 'New Project'
        }
      },
      {
        path: 'projects/:category?',
        name: 'Projects',
        props: (route) => ({
          category: route.params.category,
          teamID: Number(route.params.teamID)
        }),
        component: () => import(/* webpackChunkName: "projects" */ '../views/Projects.vue'),
        meta: {
          autoTitle: true
        }
      },
      {
        path: 'provider/new',
        name: 'NewProviderAccount',
        component: () => import(/* webpackChunkName: "new-provider" */ '../views/provider-account/NewProviderAccount.vue'),
        meta: {
          title: 'New Provider Account'
        }
      },
      {
        path: 'provider/:providerID',
        component: () => import(/* webpackChunkName: "provider-account" */ '../views/provider-account/ProviderAccount.vue'),
        props: (route) => ({
          providerID: route.params.providerID === 'demo' ? 'demo' : Number(route.params.providerID)
        }),
        children: [
          {
            path: '',
            name: 'ProviderAccount',
            component: () => import(/* webpackChunkName: "provider-account" */ '../views/provider-account/Details.vue')
          },
          {
            path: 'visualize',
            name: 'ProviderAccountDiffVisualize',
            component: () => import(/* webpackChunkName: "provider-account" */ '../views/provider-account/Visualize.vue'),
            meta: {
              hideSidebar: true
            }
          },
          {
            path: 'compare',
            name: 'ProviderAccountDiffCompare',
            component: () => import(/* webpackChunkName: "provider-account" */ '../views/provider-account/Compare.vue'),
            meta: {
              hideSidebar: true
            }
          },
          {
            path: 'diff',
            name: 'ProviderAccountDiffDiff',
            component: () => import(/* webpackChunkName: "provider-account" */ '../views/provider-account/Diff.vue'),
            meta: {
              hideSidebar: true
            }
          },
          {
            path: 'export/:tab?',
            name: 'ProviderAccountDiffExport',
            component: () => import(/* webpackChunkName: "provider-account" */ '../views/provider-account/Export.vue'),
            meta: {
              hideSidebar: true
            }
          }
        ]
      },
      {
        path: 'team/new',
        name: 'NewTeam',
        component: () => import(/* webpackChunkName: "new-team" */ '../views/NewTeam.vue'),
        meta: {
          title: 'New Team'
        }
      },
      {
        path: 'team/:teamID',
        name: 'Team',
        props: (route) => ({
          teamID: Number(route.params.teamID)
        }),
        component: () => import(/* webpackChunkName: "team" */ '../views/Team.vue'),
        meta: {
          autoTitle: true
        }
      },
      {
        path: 'gitaccount/:provider/:organization',
        name: 'GitAccount',
        props: true,
        component: () => import(/* webpackChunkName: "gitaccount" */ '../views/GitAccount.vue'),
        meta: {
          autoTitle: true
        }
      },
      {
        path: 'gitaccountdemo',
        name: 'GitAccountDemo',
        component: () => import(/* webpackChunkName: "gitaccountdemo" */ '../views/GitAccountDemo.vue'),
        meta: {
          title: 'github-integration-examples'
        }
      }
    ],
    meta: {
      title: 'Dashboard'
    }
  },
  {
    path: '/project/:id',
    component: () => import(/* webpackChunkName: "project" */ '../views/Project.vue'),
    props: (route) => ({
      id: Number(route.params.id),
      installationID: route.query.installation_id || '',
      providerName: route.query.provider || ''
    }),
    children: [
      {
        path: '',
        name: 'ProjectDesign',
        component: () => import(/* webpackChunkName: "design" */ '../components/diagram/Diagram.vue'),
        meta: {
          autoTitle: true
        }
      },
      {
        path: 'estimate/:provider?',
        name: 'ProjectEstimate',
        component: () => import(/* webpackChunkName: "estimate" */ '../components/diagram/Estimate.vue'),
        props: true,
        meta: {
          title: 'Estimate'
        }
      },
      {
        path: 'export/:tab?',
        name: 'ProjectExport',
        component: () => import(/* webpackChunkName: "export" */ '../components/diagram/Export.vue'),
        props: true,
        meta: {
          title: 'Export'
        }
      },
      {
        path: 'git/:providerName?/:installationID?',
        props: ({ params: { providerName, installationID } }) => ({
          providerName,
          installationID
        })
      }
    ]
  },
  {
    path: '/diff/:projectID/:historyOrProject/:compareTo',
    component: () => import(/* webpackChunkName: "project-diff" */ '../views/ProjectDiff.vue'),
    props: (route) => ({
      projectID: Number(route.params.projectID),
      historyOrProject: route.params.historyOrProject,
      compareTo: Number(route.params.compareTo)
    }),
    children: [
      {
        name: 'ProjectDiffVisualize',
        path: '',
        component: () => import(/* webpackChunkName: "project-diff-visualize" */ '../components/project-diff/Visualize.vue'),
        meta: {
          autoTitle: true
        }
      },
      {
        name: 'ProjectDiffCompare',
        path: 'compare',
        component: () => import(/* webpackChunkName: "project-diff-compare" */ '../components/project-diff/Compare.vue'),
        meta: {
          autoTitle: true
        }
      },
      {
        name: 'ProjectDiffDiff',
        path: 'diff',
        component: () => import(/* webpackChunkName: "project-diff-diff" */ '../components/project-diff/Diff.vue'),
        meta: {
          autoTitle: true
        }
      }
    ]
  },
  {
    path: '/pricing',
    name: 'Pricing',
    meta: {
      title: 'Pricing'
    },
    component: () => import(/* webpackChunkName: "pricing" */ '../views/PricingPlans.vue')
  },
  {
    path: '/quotas',
    name: 'Quotas',
    meta: {
      title: 'Quotas'
    },
    component: () => import(/* webpackChunkName: "quotas" */ '../views/Quotas.vue')
  },
  {
    path: '/upgrade',
    name: 'UpgradePricingPlan',
    meta: {
      title: 'Upgrade your plan'
    },
    component: () => import(/* webpackChunkName: "pricing" */ '../views/Upgrade.vue')
  },
  {
    path: '/checkout/:stripeSessionId',
    name: 'Checkout',
    props: true,
    component: () => import(/* webpackChunkName: "checkout" */ '../views/Checkout.vue')
  },
  {
    path: '/checkout-result',
    name: 'CheckoutResult',
    component: () => import(/* webpackChunkName: "checkout-result" */ '../views/CheckoutResult.vue')
  },
  { path: '*', redirect: '/' } // catch any unknown routes
]

const loggedInRoutes = [
  'Account',
  'Onboarding',
  'Overview',
  'Projects',
  'Import',
  'Project',
  'ProjectDesign',
  'ProjectEstimate',
  'ProjectExport',
  'Dashboard',
  'NewProject',
  'ProviderAccount',
  'NewProviderAccount',
  'Team',
  'NewTeam',
  'VerifyEmail',
  'UpgradePricingPlan',
  'Quotas',
  'Checkout',
  'CheckoutResult',
  'ProjectDiffVisualize',
  'ProjectDiffCompare',
  'ProjectDiffDiff'
]

const notLoggedInRoutes = [
  'Login',
  'ConnectSSO',
  'Provider',
  'ProviderOffer',
  'Authorize',
  'Register',
  'ResetPassword',
  'VerifyAccount',
  'Pricing'
]

const router = new VueRouter({
  mode: 'history',
  routes,
  linkActiveClass: 'is-active' // to put 'is-active' class automatically in the navbar
})

router.beforeEach(async (to, from, next) => {
  if (to.path !== from.path) {
    EventBus.dispatch('setPageTitle', '')
  }

  for (const url in window.abortControllers) {
    const controller = window.abortControllers[url]
    if (controller) {
      controller.abort()
    }
    delete window.abortControllers[url]
  }

  const accountStore = useAccountStore()
  let isAuthenticated = accountStore.isLoggedIn
  const logInRequired = loggedInRoutes.includes(to.name)
  const notLogInRequired = notLoggedInRoutes.includes(to.name)

  if (notLogInRequired) {
    next()
  }

  if (isAuthenticated && (to.name === 'Login' || to.name === 'ConnectSSO')) {
    next({
      name: 'Overview',
      query: from.query
    })
  }

  if (!isAuthenticated) {
    // attempt to login user if possible
    // the app displays a loading animation
    // while we try to login user when user lands on page for first time
    EventBus.dispatch('startGlobalLoading')
    await accountStore.tryInitialLogin().catch(() => {})
    isAuthenticated = accountStore.isLoggedIn
    EventBus.dispatch('stopGlobalLoading')
  }

  if (to.name === 'Authorize') {
    // SSO uses a cloud provider auth page to get authorization code
    // then once back to Authorize page, we get a token and
    // let redirect to the destination hold in the authorization state
    next()
  } else if (logInRequired && !isAuthenticated) {
    // attempt to login user if possible
    // the app displays a loading animation
    // while we try to login user when user lands on page for first time
    EventBus.dispatch('startLoadInitialToken')
    await accountStore.tryInitialLogin().catch((error) => {
      if ([500, 502].includes(error.response.status)) {
        next({ name: 'Login' })
      }
    })
    EventBus.dispatch('stopLoadInitialToken')

    // check again if we are authenticated
    isAuthenticated = accountStore.isLoggedIn
    if (isAuthenticated) {
      next()
    } else {
      next({ name: 'Login' })
    }
  } else if (to.name === 'Login' && (to.query == null || to.query.redirect == null)) {
    // add the redirect query param to the login url to let user know where he will land
    next({
      name: 'Login',
      query: {
        ...to.query,
        // remember from where we logged out in the url
        redirect:
          from.fullPath !== '/' && loggedInRoutes.includes(from.name)
            ? from.fullPath.substring(1)
            : 'dashboard'
      }
    })
  } else {
    // all is in order, continue
    next()
  }
})

router.afterEach((to) => {
  if (!to.meta.autoTitle) {
    Vue.nextTick(() => {
      document.title = makeTitle(to.meta.title || 'Home')
    })
  }
})

export default router
