<template>
  <div class="modal-card mx-auto">
    <header class="modal-card-head">
      <p class="modal-card-title">
        Install GitHub app
      </p>
      <o-button
        icon-left="close"
        class="ml-auto pl-4"
        @click="closePopup"
      />
    </header>
    <section class="modal-card-body">
      <p class="has-text-centered">
        You are trying to install the Holori GitHub App. In order to start the process, we first need you to connect your GitHub account to Holori.
      </p>

      <div class="is-flex is-justify-content-center my-3">
        <SSOButton
          :provider="{ id: 'github', name: 'Login with GitHub' }"
          @click="logout"
        />
      </div>

      <p class="has-text-centered">
        Once done, you can come back here and start the Holori GitHub App installation.
      </p>
    </section>
    <footer class="card-footer">
      <o-button
        variant="primary"
        class="card-footer-item is-border-top-radius-none"
        @click="closePopup"
      >
        OK
      </o-button>
    </footer>
  </div>
</template>

<script>
import SSOButton from '@/components/SSOButton.vue'
import EventBus from '@/common/eventBus'

export default {
  name: 'ImportingProjectModal',

  components: {
    SSOButton
  },

  methods: {
    closePopup () {
      this.$emit('close')
    },

    logout (e) {
      e.preventDefault()

      this.closePopup()

      window.localStorage.setItem('installGithubApp', 'true')
      EventBus.dispatch('logout', { sso: 'github' })
    }
  }
}
</script>
