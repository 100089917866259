import accountApi from '@/api/modules/main-server/account'
import jwtDecode from 'jwt-decode'
import { defineStore } from 'pinia'
import { useUserStore } from './user'

export const useAccountStore = defineStore('account', {
  state: () => ({
    email: '',
    password: '',
    accessToken: null,
    userID: null,
    tokenRefresher: null,
    quotas: []
  }),

  persist: {
    storage: localStorage,
    paths: ['userID']
  },

  getters: {
    isLoggedIn (state) {
      if (state.userID < 1 || !state.accessToken) {
        return false
      }
      const tokenExpiration = jwtDecode(state.accessToken).exp
      return (tokenExpiration * 1000) >= Date.now()
    },
    getAccessToken: (state) => state.accessToken,
    findQuota: (state) => {
      return (name) => {
        const found = state.quotas.find((obj) => obj.name === name)

        if (found) {
          return found
        }

        const userStore = useUserStore()
        return {
          limit: userStore.getQuota(name),
          current: userStore.getQuotaValue(name)
        }
      }
    },
    hasReachedQuota () {
      return (name) => {
        const quota = this.findQuota(name)

        if (quota) {
          const { current, limit } = quota

          if (limit === null) {
            return false
          }

          return current >= limit
        } else {
          const userStore = useUserStore()
          return userStore.hasReachedQuota(name)
        }
      }
    },
    getQuotaLimit () {
      return (name) => this.findQuota(name)?.limit || -1
    }
  },

  actions: {
    register (formData) {
      return accountApi.register(formData)
    },
    resetPassword (formData) {
      return accountApi.resetPassword(formData)
    },
    changePassword (formData) {
      return accountApi.changePassword(formData)
    },
    changePasswordFromToken (formData) {
      return accountApi.changePasswordFromToken(formData)
    },
    verify (formData) {
      return accountApi.verify(formData)
    },
    changeEmail (formData) {
      return accountApi.changeEmail(formData)
    },
    login (formData) {
      return new Promise(
        (resolve, reject) => {
          accountApi.login(formData).then(
            (response) => {
              this.onLogin(response).then((data) => resolve(data))
            },
            (error) => reject(error)
          )
        }
      )
    },
    onLogin (response) {
      this.resetTokenRefresher()
      this.dropCredentials()
      const decodedToken = jwtDecode(response.data.access_token)
      const tokenExpiration = decodedToken.exp
      const userID = +(decodedToken.sub.replace('user:', ''))
      // remember connected user id
      this.setUserID(userID)
      // remember user token (and id)
      this.setToken(response.data.access_token)
      // setup token refresh
      this.setRefresher(tokenExpiration)
      const user = useUserStore()
      return new Promise((resolve) => {
        user.initUserData(userID, resolve)
      })
    },
    getAuthorizeCodeSSOURL (payload) {
      return accountApi.getAuthorizeCodeSSOURL(payload.provider)
    },
    authorizeSSO (payload) {
      return new Promise(
        (resolve, reject) => {
          accountApi.authorizeSSO(payload.provider, payload.code, payload.state).then(
            (response) => {
              this.onLogin(response).then((userData) => {
                resolve(userData)
              }).catch((error) => reject(error))
            },
            (error) => reject(error)
          )
        }
      )
    },
    setRefresher (tokenExpiration) {
      // compute timeout in milliseconds
      const timeout = tokenExpiration * 1000 - Date.now()
      this.setTokenRefresher(setTimeout(
        () => {
          this.renewToken()
        }, timeout
      ))
    },
    tryInitialLogin () {
      return new Promise(
        (resolve, reject) => {
          accountApi.renewToken()
            .then(
              (response) => {
                this.onLogin(response)
                resolve()
              },
              (error) => reject(error)
            )
        }
      )
    },
    renewToken () {
      // erase refresher
      this.resetTokenRefresher()
      // perform renewal
      return new Promise(
        (resolve, reject) => {
          accountApi.renewToken()
            .then(
              (response) => {
                // new token
                this.setToken(response.data.access_token)
                // (re)set token refresher
                const tokenExpiration = jwtDecode(response.data.access_token).exp
                this.setRefresher(tokenExpiration)
                // continue
                resolve(response)
              },
              (error) => {
                // TODO allow user to retry to not loose everything ?
                this.logout()
                reject(error)
              }
            )
            .catch(() => {})
        }
      )
    },
    getQuotas () {
      return new Promise(
        (resolve, reject) => {
          accountApi.getQuotas()
            .then(
              (response) => {
                this.setQuotas(response.data.quotas)
                resolve(response)
              },
              (error) => {
                reject(error)
              }
            )
        }
      )
    },
    logout () {
      return accountApi.logout().catch(() => {}).finally(
        () => {
          // finally, in all cases, drop all token and user data
          this.resetTokenRefresher()
          this.dropToken()
          this.dropUserID()
          this.dropCredentials()

          const user = useUserStore()
          user.setUser(null)
        }
      )
    },

    setToken (accessToken) {
      this.accessToken = accessToken
    },
    setUserID (userID) {
      this.userID = userID
    },
    setIsNewUser (isNew) {
      this.isNewUser = isNew
    },
    setQuotas (quotas) {
      this.quotas = quotas
    },

    dropCredentials (state) {
      this.email = ''
      this.password = ''
    },
    dropToken (state) {
      this.accessToken = null
    },
    dropUserID (state) {
      this.userID = 0
    },

    setTokenRefresher (tokenRefresher) {
      this.tokenRefresher = tokenRefresher
    },
    resetTokenRefresher (state) {
      if (this.tokenRefresher) {
        clearTimeout(this.tokenRefresher)
        this.tokenRefresher = null
      }
    }
  }
})
